import { RoutePermissionGate } from "components/layout/AccessControl";
import AuthLayout from "components/layout/AuthLayout";
import Layout from "components/layout/Layout";
import UiKitLayout from "components/layout/UiKitLayout";
import Loadable from "lib/suspenseLoader/Loadable";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import { PATHS } from "configs/routeConfig";

import GuestRoute from "./GuestRoutes";
import PrivateRoute from "./PrivateRoutes";

// import PrivateRoute from "./PrivateRoutes";

/** Auth */
const Login = Loadable(lazy(() => import("modules/auth/login/Login")));
// const OtpDetails = Loadable(
//   lazy(() => import("modules/auth/otpVerification/OtpDetails"))
// );
// const OtpVerification = Loadable(
//   lazy(() => import("modules/auth/otpVerification/OtpVerification"))
// );
const ForgotPassword = Loadable(
  lazy(() => import("modules/auth/forgotPassword/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("modules/auth/resetPassword/ResetPassword"))
);

/** Dashboard */
const Dashboard = Loadable(lazy(() => import("modules/dashboard/Dashboard")));

/** User Management*/
const UserManagement = Loadable(
  lazy(() => import("modules/userManagement/UserManagement"))
);
// const UserList = Loadable(
//   lazy(() => import("modules/userManagement/userList/UserList"))
// );
const AdminUsers = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AdminUsers"))
);
// const UserDetails = Loadable(
//   lazy(() => import("modules/userManagement/userDetails/ViewUserDetails"))
// );
const AddUser = Loadable(
  lazy(() => import("modules/userManagement/history/AddClient"))
);
const Clients = Loadable(
  lazy(() => import("modules/userManagement/clients/Clients"))
);
const ClientsDashboard = Loadable(
  lazy(() => import("modules/userManagement/clients/ClientDashboard"))
);

const ClientDetails = Loadable(
  lazy(() => import("modules/userManagement/clientDetails/ClientDetails"))
);
const History = Loadable(
  lazy(() => import("modules/userManagement/history/History"))
);
const AddClient = Loadable(
  lazy(() => import("modules/userManagement/history/AddClient"))
);
const PortfolioUsers = Loadable(
  lazy(() => import("modules/userManagement/portfolioUsers/PortfolioUsers"))
);
const AddNewContact = Loadable(
  lazy(() => import("modules/userManagement/portfolioUsers/AddNewContact"))
);
const ContactDetails = Loadable(
  lazy(() => import("modules/userManagement/portfolioUsers/ViewContactDetails"))
);
const PortfolioConfiguration = Loadable(
  lazy(() =>
    import("modules/userManagement/portfolioUsers/ConfigurationPortfolio")
  )
);
const PortfolioReferalSource = Loadable(
  lazy(() => import("modules/userManagement/portfolioUsers/ReferalSourceData"))
);
const AdminUserDetails = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/ViewAdminUserDetails"))
);
const AdminUserAddEdit = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AddEditAdminUser"))
);
const AdminRoles = Loadable(
  lazy(() => import("modules/userManagement/adminUsers/AdminRoles"))
);

/** Demos */
const Demos = Loadable(lazy(() => import("modules/demos/demos")));
const AccessDemoRequest = Loadable(
  lazy(() => import("modules/demos/AccessDemoRequest/AccessDemoRequest"))
);
const DemoDetails = Loadable(
  lazy(() => import("modules/demos/AccessDemoRequest/DemoDetails"))
);
const DemoDetailEdit = Loadable(
  lazy(() => import("modules/demos/AccessDemoRequest/DemoDetailEdit"))
);
const BookedMeeting = Loadable(
  lazy(() => import("modules/demos/BookedMeeting/BookedMeeting"))
);
const BookedMeetingView = Loadable(
  lazy(() => import("modules/demos/BookedMeeting/BookedMeetingView"))
);
const BookedMeetingEdit = Loadable(
  lazy(() => import("modules/demos/BookedMeeting/BookedMeetingEdit"))
);
const DemoUsers = Loadable(
  lazy(() => import("modules/demos/DemoUsers/DemoUsers"))
);
const DemoUserDetails = Loadable(
  lazy(() => import("modules/demos/DemoUsers/DemoDetailsUsers"))
);
const EditDemoUserDetails = Loadable(
  lazy(() => import("modules/demos/DemoUsers/EditDemoUser"))
);
// const NewDemoUserDetails = Loadable(
//   lazy(() => import("modules/demos/DemoUsers/newDemoUser/DetailsNewDemoUser"))
// );
// const EditNewDemoUserDetails = Loadable(
//   lazy(() => import("modules/demos/DemoUsers/newDemoUser/EditNewDemoUsers"))
// );
const DemoConfiguration = Loadable(
  lazy(() => import("modules/demos/AccessDemoRequest/DemoConfiguration"))
);
const MeetingConfiguration = Loadable(
  lazy(() => import("modules/demos/BookedMeeting/MeetingConfiguration"))
);
const ScheduledDemo = Loadable(
  lazy(() => import("modules/demos/ScheduledDemo"))
);
const ScheduledMeeting = Loadable(
  lazy(() => import("modules/demos/ScheduleMeeting"))
);
/** Content Management*/
const ContentManagement = Loadable(
  lazy(() => import("modules/contentManagement/ContentManagement"))
);
const StaticPages = Loadable(
  lazy(() => import("modules/contentManagement/staticPages/StaticPages"))
);
const StatisPagesViewDetails = Loadable(
  lazy(() =>
    import("modules/contentManagement/staticPages/ViewStaticPageDetails")
  )
);
const AddStaticPage = Loadable(
  lazy(() =>
    import(
      "modules/contentManagement/staticPages/StaticPagesActions/AddStaticPage"
    )
  )
);
const BlogsPage = Loadable(
  lazy(() => import("modules/contentManagement/blogs/Blogs"))
);
// const BlogList = Loadable(
//   lazy(() => import("modules/contentManagement/blogs/blogList/BlogList"))
// );
// const AddBlog = Loadable(
//   lazy(() =>
//     import("modules/contentManagement/blogs/blogList/BlogActions/AddBlog")
//   )
//);
// const ViewBlogDetails = Loadable(
//   lazy(() =>
//     import(
//       "modules/contentManagement/blogs/blogList/BlogActions/ViewBlogDetails"
//     )
//   )
// );

const BlogCategory = Loadable(
  lazy(() =>
    import("modules/contentManagement/blogs/blogCategory/BlogCategory")
  )
);
const AddCategory = Loadable(
  lazy(() =>
    import(
      "modules/contentManagement/blogs/blogCategory/BlogCategoryActions/AddCategory"
    )
  )
);
const FaqsPage = Loadable(
  lazy(() => import("modules/contentManagement/faq/Faq"))
);
const FaqsList = Loadable(
  lazy(() => import("modules/contentManagement/faq/faqList/FaqsList"))
);
const AddFaq = Loadable(
  lazy(() => import("modules/contentManagement/faq/faqList/faqActions/AddFaq"))
);
const FaqsCategory = Loadable(
  lazy(() => import("modules/contentManagement/faq/faqCategory/FaqCategory"))
);
const AddFaqCategory = Loadable(
  lazy(() =>
    import(
      "modules/contentManagement/faq/faqCategory/faqActions/AddFaqCategory"
    )
  )
);

/** Payment */
const Payment = Loadable(lazy(() => import("modules/payment/Payment")));

/** Role */
const RoleManagement = Loadable(
  lazy(() => import("modules/role/RoleManagement"))
);
const RolePage = Loadable(lazy(() => import("modules/role/Role")));
const AddEditRole = Loadable(lazy(() => import("modules/role/AddEditRole")));
const ViewRole = Loadable(lazy(() => import("modules/role/ViewRole")));

/** Email And Notifications */
const EmailTemplatesManagement = Loadable(
  lazy(() => import("modules/emailTemplates/EmailTemplatesManagement"))
);
const EmailTemplates = Loadable(
  lazy(() => import("modules/emailTemplates/EmailTemplates"))
);
const AddEditTemplates = Loadable(
  lazy(() => import("modules/emailTemplates/AddEditTemplates"))
);
const ViewTemplates = Loadable(
  lazy(() => import("modules/emailTemplates/ViewTemplates"))
);

/** Master Management */
const MasterManagement = Loadable(
  lazy(() => import("modules/masterManagement/MasterManagement"))
);
const CountryPage = Loadable(
  lazy(() => import("modules/masterManagement/country/Country"))
);
const AddCountry = Loadable(
  lazy(() => import("modules/masterManagement/country/AddCountry"))
);
const CurrencyPage = Loadable(
  lazy(() => import("modules/masterManagement/currency/Currency"))
);
const AddCurrency = Loadable(
  lazy(() => import("modules/masterManagement/currency/AddCurrency"))
);
const AddLanguage = Loadable(
  lazy(() => import("modules/masterManagement/language/AddLanguage"))
);
const Industry = Loadable(
  lazy(() => import("modules/masterManagement/industry/Industry"))
);
const AddIndustry = Loadable(
  lazy(() => import("modules/masterManagement/industry/AddIndustry"))
);
const OrganizationSize = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationSize/OrganizationSize")
  )
);
const DomainOfWork = Loadable(
  lazy(() => import("modules/masterManagement/domainofWork/DomainOfWork"))
);

const AddDomainOfWork = Loadable(
  lazy(() => import("modules/masterManagement/domainofWork/AddDomainOfWork"))
);
const MeansOfTransportation = Loadable(
  lazy(() =>
    import(
      "modules/masterManagement/meansOfTransportation/MeansOfTransportation"
    )
  )
);

const AddMeansOfTransportation = Loadable(
  lazy(() =>
    import(
      "modules/masterManagement/meansOfTransportation/AddMeansOfTransportation"
    )
  )
);

const OrganizationGoal = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationGoal/OrganizationGoal")
  )
);
const AddOrganizationGoal = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationGoal/AddOrganizationGoal")
  )
);
const OrganizationDevice = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationDevice/OrganizationDevice")
  )
);
const AddOrganizationDevice = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationDevice/AddOrganizationDevice")
  )
);
const ReferralSource = Loadable(
  lazy(() => import("modules/masterManagement/referralSource/ReferralSource"))
);
const AddReferralSource = Loadable(
  lazy(() =>
    import("modules/masterManagement/referralSource/AddReferralSource")
  )
);
const AddOrganization = Loadable(
  lazy(() =>
    import("modules/masterManagement/organizationSize/AddOrganization")
  )
);

const CivilStatus = Loadable(
  lazy(() => import("modules/masterManagement/civilStatus/CivilStatus"))
);
const AddCivilStatus = Loadable(
  lazy(() => import("modules/masterManagement/civilStatus/AddCivilStatus"))
);

const ContractType = Loadable(
  lazy(() => import("modules/masterManagement/contractType/ContractType"))
);
const AddContractType = Loadable(
  lazy(() => import("modules/masterManagement/contractType/AddContractType"))
);

const WorkArrangement = Loadable(
  lazy(() => import("modules/masterManagement/workArrangement/WorkArrangement"))
);
const AddWorkArrangement = Loadable(
  lazy(() =>
    import("modules/masterManagement/workArrangement/AddWorkArrangement")
  )
);
const JobPosition = Loadable(
  lazy(() => import("modules/masterManagement/jobPosition/JobPosition"))
);
const AddEditJobPosition = Loadable(
  lazy(() => import("modules/masterManagement/jobPosition/AddEditJobPosition"))
);
const EmployeeType = Loadable(
  lazy(() => import("modules/masterManagement/employeeType/EmployeeType"))
);
const AddEditEmployeeType = Loadable(
  lazy(() =>
    import("modules/masterManagement/employeeType/AddEditEmployeeType")
  )
);

const BreaksName = Loadable(
  lazy(() => import("modules/masterManagement/breaksName/BreaksName"))
);
const AddBreaksName = Loadable(
  lazy(() => import("modules/masterManagement/breaksName/AddBreaksName"))
);

const Deductions = Loadable(
  lazy(() => import("modules/masterManagement/deductions/Deductions"))
);
const AddDeductions = Loadable(
  lazy(() => import("modules/masterManagement/deductions/AddDeductions"))
);

const TimezonePage = Loadable(
  lazy(() => import("modules/masterManagement/timezone/Timezone"))
);
const LanguagePage = Loadable(
  lazy(() => import("modules/masterManagement/language/Language"))
);
/** Subscription Packages */
const SubscriptionPackagesManagement = Loadable(
  lazy(() =>
    import("modules/subscriptionPackages/SubscriptionPackagesManagement")
  )
);
const SubscriptionPackages = Loadable(
  lazy(() => import("modules/subscriptionPackages/SubscriptionPackages"))
);
const AddSubscriptionPackages = Loadable(
  lazy(() => import("modules/subscriptionPackages/AddSubscriptionPackages"))
);

/** Support */
const SupportManagement = Loadable(
  lazy(() => import("modules/support/SupportManagement"))
);
const Support = Loadable(lazy(() => import("modules/support/Support")));
// const TicketDetail = Loadable(
//   lazy(() => import("modules/support/TicketDetails"))
// );
/** Settings */
const Settings = Loadable(lazy(() => import("modules/settings/Settings")));
const GeneralSettings = Loadable(
  lazy(() => import("modules/settings/generalSettings/GeneralSettings"))
);
const EmailNotifications = Loadable(
  lazy(() => import("modules/settings/emailNotifications/EmailNotifications"))
);
const PaymentGateway = Loadable(
  lazy(() => import("modules/settings/paymentGateway/PaymentGateway"))
);
const SmtpSmsDetails = Loadable(
  lazy(() => import("modules/settings/smtpSmsDetails/SmtpSmsDetails"))
);
const SocialMedia = Loadable(
  lazy(() => import("modules/settings/socialMedial/SocialMedia"))
);
const LanguageMessages = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/LanguageMessages"))
);
const AddLanguageMessages = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/AddLanguageMessage"))
);
const LangaugeStaticLang = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/LanguageStaticLang"))
);
const AddLanguageStaticLang = Loadable(
  lazy(() => import("modules/settings/langaugePreferences/AddStaticLabel"))
);

/** Home Page CMS */
const Cms = Loadable(lazy(() => import("modules/HomePageCMS/Cms")));
const MainBanner = Loadable(
  lazy(() => import("modules/HomePageCMS/MainBanner/MainBanner"))
);
const Partners = Loadable(
  lazy(() => import("modules/HomePageCMS/Partners/Partners"))
);
const AddEditPartners = Loadable(
  lazy(() => import("modules/HomePageCMS/Partners/AddEditPartners"))
);
const Benefits = Loadable(
  lazy(() => import("modules/HomePageCMS/Benefits/Benefits"))
);
const AddEditBenefits = Loadable(
  lazy(() => import("modules/HomePageCMS/Benefits/AddEditBenefits"))
);
const HowWeWork = Loadable(
  lazy(() => import("modules/HomePageCMS/HowWeWork/HowWeWork"))
);
const AddEditHowWeWork = Loadable(
  lazy(() => import("modules/HomePageCMS/HowWeWork/AddEditHowWeWork"))
);
const Testimonials = Loadable(
  lazy(() => import("modules/HomePageCMS/Testimonials/Testimonials"))
);
const AddEditTestimonials = Loadable(
  lazy(() => import("modules/HomePageCMS/Testimonials/AddEditTestimonials"))
);

const Capabilities = Loadable(
  lazy(() => import("modules/HomePageCMS/Capabilities/Capabilities"))
);
const AddEditCapabilities = Loadable(
  lazy(() => import("modules/HomePageCMS/Capabilities/AddEditCapabilities"))
);

/** Master Management */
// const CommonPages = Loadable(lazy(() => import("modules/commonPages/CommonPages")));
const FourZeroFourPage = Loadable(
  lazy(() => import("modules/commonPages/FourZeroFourPage"))
);
const ThankyouPage = Loadable(
  lazy(() => import("modules/commonPages/ThankyouPage"))
);
const ComingSoonPage = Loadable(
  lazy(() => import("modules/commonPages/ComingSoonPage"))
);

/** My Profile */
const MyProfile = Loadable(lazy(() => import("modules/myProfile/MyProfile")));
const EditMyProfile = Loadable(
  lazy(() => import("modules/myProfile/EditProfile"))
);

/** Change Password */
const ChangePasswordPage = Loadable(
  lazy(() => import("modules/changePassword/ChangePassword"))
);

/** UI Kit */
const UiKit = Loadable(lazy(() => import("modules/uiKit/UiKit")));
const UiTypography = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiTypograpgy"))
);
const UiColors = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiColors"))
);
const UiForms = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiForms"))
);
const UiCards = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiCards"))
);
const UiBlocks = Loadable(
  lazy(() => import("modules/uiKit/uiComponents/UiBlocks"))
);

const MainRoutes = {
  path: PATHS.DEFAULT,
  element: (
    <PrivateRoute>
      <Layout />
    </PrivateRoute>
  ),
  children: [
    // Dashboard
    {
      path: PATHS.DASHBOARD,
      element: <Dashboard />,
    },
    // User management
    {
      path: PATHS.USER,
      element: <UserManagement />,
      children: [
        // {
        //   // index: true,
        //   path: PATHS.USER_LIST,
        //   element: (
        //     <RoutePermissionGate>
        //       <UserList />
        //     </RoutePermissionGate>
        //   ),
        // },
        // {
        //   path: PATHS.USER_LIST + "/" + PATHS.VIEW + "/" + ":id",
        //   element: (
        //     <RoutePermissionGate>
        //       <UserDetails />
        //     </RoutePermissionGate>
        //   ),
        // },
        // {
        //   path: PATHS.USER_LIST + "/" + PATHS.EDIT + "/" + ":id",
        //   element: (
        //     <RoutePermissionGate>
        //       <AddUser type="edit" />
        //     </RoutePermissionGate>
        //   ),
        // },
        {
          path: PATHS.CLIENTSDASHBOARD,
          element: (
            <RoutePermissionGate>
              <Clients />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CLIENTSDASHBOARD + "/" + PATHS.TODAYS_NEW_CLIENT,
          element: (
            <RoutePermissionGate>
              <ClientsDashboard />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CLIENTS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddUser type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CLIENTS + "/" + PATHS.CLIENT_DETAILS + "/" + PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <ClientDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CLIENTS,
          element: (
            <RoutePermissionGate>
              <History />
            </RoutePermissionGate>
          ),
        },
        // {
        //   path: PATHS.USER_LIST + "/" + PATHS.VIEW + "/" + ":id",
        //   element: (
        //     <RoutePermissionGate>
        //       <UserDetails />
        //     </RoutePermissionGate>
        //   ),
        // },
        {
          path: PATHS.CLIENTS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddClient type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CLIENTS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddClient type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PORTFOLIO_USERS,
          element: (
            <RoutePermissionGate>
              <PortfolioUsers />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PORTFOLIO_USERS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddNewContact type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PORTFOLIO_USERS + "/" + PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <ContactDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PORTFOLIO_USERS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddNewContact type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.PORTFOLIO_USERS + "/" + PATHS.PORTFOLIO_CONFIGURATION,
          element: (
            <RoutePermissionGate>
              <PortfolioConfiguration />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.PORTFOLIO_USERS + "/" + PATHS.PORTFOLIO_REFERAL_SOURCE,
          element: (
            <RoutePermissionGate>
              <PortfolioReferalSource />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER,
          element: (
            <RoutePermissionGate>
              <AdminUsers />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <AdminUserDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AdminUserAddEdit type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.EDIT + "/:id",
          element: (
            <RoutePermissionGate>
              <AdminUserAddEdit type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ADMIN_USER + "/" + PATHS.ADMIN_ROLES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AdminRoles type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path:
            PATHS.ADMIN_USER +
            "/" +
            PATHS.ADMIN_ROLES +
            "/" +
            PATHS.EDIT +
            "/:id",
          element: (
            <RoutePermissionGate>
              <AdminRoles type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: <Navigate to={`${PATHS.USER}/${PATHS.CLIENTS}`} />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={`${PATHS.USER}/${PATHS.CLIENTS}`} />,
        },
      ],
    },
    // Demos
    {
      path: PATHS.DEMOS,
      element: <Demos />,
      children: [
        {
          // index: true,
          path: PATHS.DEMO_REQUEST,
          element: (
            <RoutePermissionGate>
              <AccessDemoRequest />
            </RoutePermissionGate>
          ),
        },

        {
          // index: true,
          path: PATHS.DEMO_REQUEST + "/" + PATHS.SCHEDULE_DEMO,
          element: (
            <RoutePermissionGate>
              <ScheduledDemo />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.DEMO_REQUEST + "/" + PATHS.DEMO_CONFIGURATION,
          element: (
            <RoutePermissionGate>
              <DemoConfiguration />
            </RoutePermissionGate>
          ),
        },

        {
          path: PATHS.DEMO_REQUEST + "/" + PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <DemoDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DEMO_REQUEST + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <DemoDetailEdit type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DEMO_REQUEST + "/" + PATHS.EDIT + "/:id",
          element: (
            <RoutePermissionGate>
              <DemoDetailEdit type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.BOOKED_MEETING,
          element: (
            <RoutePermissionGate>
              <BookedMeeting />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.BOOKED_MEETING + "/" + PATHS.SCHEDULE_MEETING,
          element: (
            <RoutePermissionGate>
              <ScheduledMeeting />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.BOOKED_MEETING + "/" + PATHS.MEETING_CONFIGURATION,
          element: (
            <RoutePermissionGate>
              <MeetingConfiguration />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.BOOKED_MEETING + "/" + PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <BookedMeetingView type="view" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BOOKED_MEETING + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <BookedMeetingEdit type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BOOKED_MEETING + "/" + PATHS.EDIT + "/:id",
          element: (
            <RoutePermissionGate>
              <BookedMeetingEdit type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          // index: true,
          path: PATHS.DEMO_USERS,
          element: (
            <RoutePermissionGate>
              <DemoUsers />
            </RoutePermissionGate>
          ),
        },

        {
          path: PATHS.DEMO_USERS + "/" + PATHS.VIEW + "/:id",
          element: (
            <RoutePermissionGate>
              <DemoUserDetails type="view" />
            </RoutePermissionGate>
          ),
        },
        // {
        //   path: PATHS.DEMO_USERS + "/" + PATHS.EDIT + "/:id",
        //   element: (
        //     <RoutePermissionGate>
        //       <EditNewDemoUserDetails type="edit" />
        //     </RoutePermissionGate>
        //   ),
        // },
        // {
        //   path: PATHS.DEMO_USERS + "/" + PATHS.VIEW + "/:id",
        //   element: (
        //     <RoutePermissionGate>
        //       <NewDemoUserDetails type="view" />
        //     </RoutePermissionGate>
        //   ),
        // },
        {
          path: PATHS.DEMO_USERS + "/" + PATHS.EDIT + "/:id",
          element: (
            <RoutePermissionGate>
              <EditDemoUserDetails type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: <Navigate to={`${PATHS.DEMOS}/${PATHS.DEMO_REQUEST}`} />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={`${PATHS.DEMOS}/${PATHS.DEMO_REQUEST}`} />,
        },
      ],
    },
    // Content management
    {
      path: PATHS.CONTENT,
      element: <ContentManagement />,
      children: [
        // Static Pages
        {
          path: PATHS.STATIC_PAGES,
          element: (
            <RoutePermissionGate>
              <StaticPages />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <StatisPagesViewDetails />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddStaticPage type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.STATIC_PAGES + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddStaticPage type="edit" />
            </RoutePermissionGate>
          ),
        },
        // Blogs
        {
          path: PATHS.BLOGS,
          element: <BlogsPage />,
          children: [
            // {
            //   path: PATHS.BLOG_LIST,
            //   element: (
            //     <RoutePermissionGate>
            //       <BlogList />
            //     </RoutePermissionGate>
            //   ),
            // },
            // {
            //   path: PATHS.BLOG_LIST + "/" + PATHS.VIEW,
            //   element: (
            //     <RoutePermissionGate>
            //       <ViewBlogDetails />
            //     </RoutePermissionGate>
            //   ),
            // },
            // {
            //   path: PATHS.BLOG_LIST + "/" + PATHS.CREATE,
            //   element: (
            //     <RoutePermissionGate>
            //       <AddBlog type="add" />
            //     </RoutePermissionGate>
            //   ),
            // },
            // {
            //   path: PATHS.BLOG_LIST + "/" + PATHS.EDIT,
            //   element: (
            //     <RoutePermissionGate>
            //       <AddBlog type="edit" />
            //     </RoutePermissionGate>
            //   ),
            // },
            {
              path: PATHS.BLOG_CATEGORY,
              element: (
                <RoutePermissionGate>
                  <BlogCategory />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.BLOG_CATEGORY + "/" + PATHS.CREATE,
              element: (
                <RoutePermissionGate>
                  <AddCategory type="add" />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.BLOG_CATEGORY + "/" + PATHS.EDIT,
              element: (
                <RoutePermissionGate>
                  <AddCategory type="edit" />
                </RoutePermissionGate>
              ),
            },
            {
              path: "",
              element: (
                <Navigate
                  to={`${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_LIST}`}
                />
              ),
            },
            {
              path: PATHS.ANY,
              element: (
                <Navigate
                  to={`${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_LIST}`}
                />
              ),
            },
          ],
        },
        // Faqs
        {
          path: PATHS.FAQS,
          element: <FaqsPage />,
          children: [
            {
              path: PATHS.FAQS_LIST,
              element: (
                <RoutePermissionGate>
                  <FaqsList />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.FAQS_LIST + "/" + PATHS.EDIT,
              element: (
                <RoutePermissionGate>
                  <AddFaq type="edit" />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.FAQS_LIST + "/" + PATHS.CREATE,
              element: (
                <RoutePermissionGate>
                  <AddFaq type="add" />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.FAQS_CATEGORY,
              element: (
                <RoutePermissionGate>
                  <FaqsCategory />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.FAQS_CATEGORY + "/" + PATHS.CREATE,
              element: (
                <RoutePermissionGate>
                  <AddFaqCategory type="add" />
                </RoutePermissionGate>
              ),
            },
            {
              path: PATHS.FAQS_CATEGORY + "/" + PATHS.EDIT,
              element: (
                <RoutePermissionGate>
                  <AddFaqCategory type="edit" />
                </RoutePermissionGate>
              ),
            },
            {
              path: "",
              element: (
                <Navigate
                  to={`${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_LIST}`}
                />
              ),
            },
            {
              path: PATHS.ANY,
              element: (
                <Navigate
                  to={`${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_LIST}`}
                />
              ),
            },
          ],
        },
        {
          path: "",
          element: <Navigate to={`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`} />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`} />,
        },
      ],
    },
    // Payment
    {
      path: PATHS.PAYMENT,
      element: <Payment />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <Payment />
            </RoutePermissionGate>
          ),
        },
      ],
    },

    // Role
    {
      path: PATHS.ROLE,
      element: <RoleManagement />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <RolePage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditRole type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EDIT + "/" + ":id",
          element: (
            <RoutePermissionGate>
              <AddEditRole type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.VIEW + "/" + ":id",
          element: (
            <RoutePermissionGate>
              <ViewRole type="view" />
            </RoutePermissionGate>
          ),
        },
      ],
    },
    // Email And Notifications
    {
      path: PATHS.EMAIL_TEMPLATES,
      element: <EmailTemplatesManagement />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <EmailTemplates />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditTemplates type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditTemplates type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.VIEW,
          element: (
            <RoutePermissionGate>
              <ViewTemplates type="view" />
            </RoutePermissionGate>
          ),
        },
      ],
    },
    // Master Management
    {
      path: PATHS.MASTER_MANAGEMENT,
      element: <MasterManagement />,
      children: [
        {
          path: PATHS.COUNTRY,
          element: (
            <RoutePermissionGate>
              <CountryPage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.COUNTRY + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddCountry type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CURRENCY,
          element: (
            <RoutePermissionGate>
              <CurrencyPage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CURRENCY + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddCurrency type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.TIMEZONE,
          element: (
            <RoutePermissionGate>
              <TimezonePage />
            </RoutePermissionGate>
          ),
        },

        {
          path: PATHS.LANGUAGE,
          element: (
            <RoutePermissionGate>
              <LanguagePage />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguage type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE + "/" + PATHS.EDIT + "/" + ":id",
          element: (
            <RoutePermissionGate>
              <AddLanguage type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.INDUSTRY,
          element: (
            <RoutePermissionGate>
              <Industry />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.INDUSTRY + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddIndustry type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.INDUSTRY + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddIndustry type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATIONSIZE,
          element: (
            <RoutePermissionGate>
              <OrganizationSize />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATIONSIZE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddOrganization type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATIONSIZE + "/" + PATHS.EDIT + "/" + ":id",
          element: (
            <RoutePermissionGate>
              <AddOrganization type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DOMAIN_OF_WORK,
          element: (
            <RoutePermissionGate>
              <DomainOfWork />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DOMAIN_OF_WORK + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddDomainOfWork type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DOMAIN_OF_WORK + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddDomainOfWork type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.MEANS_OF_TRANSPORTATION,
          element: (
            <RoutePermissionGate>
              <MeansOfTransportation />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.MEANS_OF_TRANSPORTATION + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddMeansOfTransportation type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.MEANS_OF_TRANSPORTATION + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddMeansOfTransportation type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_GOAL,
          element: (
            <RoutePermissionGate>
              <OrganizationGoal />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_GOAL + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddOrganizationGoal type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_GOAL + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddOrganizationGoal type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_DEVICE,
          element: (
            <RoutePermissionGate>
              <OrganizationDevice />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_DEVICE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddOrganizationDevice type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.ORGANIZATION_DEVICE + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddOrganizationDevice type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.REFERRAL_SOURCE,
          element: (
            <RoutePermissionGate>
              <ReferralSource />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.REFERRAL_SOURCE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddReferralSource type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.REFERRAL_SOURCE + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddReferralSource type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CIVIL_STATUS,
          element: (
            <RoutePermissionGate>
              <CivilStatus />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CIVIL_STATUS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddCivilStatus type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CIVIL_STATUS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddCivilStatus type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CONTRACT_TYPE,
          element: (
            <RoutePermissionGate>
              <ContractType />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CONTRACT_TYPE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddContractType type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CONTRACT_TYPE + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddContractType type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.WORK_ARRANGEMENT,
          element: (
            <RoutePermissionGate>
              <WorkArrangement />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.WORK_ARRANGEMENT + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddWorkArrangement type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.WORK_ARRANGEMENT + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddWorkArrangement type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.JOB_POSITION,
          element: (
            <RoutePermissionGate>
              <JobPosition />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.JOB_POSITION + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditJobPosition type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.JOB_POSITION + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditJobPosition type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EMPLOYEE_TYPE,
          element: (
            <RoutePermissionGate>
              <EmployeeType />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EMPLOYEE_TYPE + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditEmployeeType type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EMPLOYEE_TYPE + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditEmployeeType type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BREAKS_NAME,
          element: (
            <RoutePermissionGate>
              <BreaksName />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BREAKS_NAME + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddBreaksName type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BREAKS_NAME + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddBreaksName type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DEDUCTIONS,
          element: (
            <RoutePermissionGate>
              <Deductions />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DEDUCTIONS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddDeductions type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.DEDUCTIONS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddDeductions type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: (
            <Navigate to={`${PATHS.MASTER_MANAGEMENT}/${PATHS.LANGUAGE}`} />
          ),
        },
        {
          path: PATHS.ANY,
          element: (
            <Navigate to={`${PATHS.MASTER_MANAGEMENT}/${PATHS.LANGUAGE}`} />
          ),
        },
      ],
    },
    // Support
    {
      path: PATHS.SUPPORT,
      element: <SupportManagement />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <Support />
            </RoutePermissionGate>
          ),
        },
        // {
        //   path: PATHS.VIEW + "/:id",
        //   element: (
        //     <RoutePermissionGate>
        //       <TicketDetail />
        //     </RoutePermissionGate>
        //   ),
        // },
      ],
    },

    // Subscription Packages
    {
      path: PATHS.SUBSCRIPTION_PACKAGES,
      element: <SubscriptionPackagesManagement />,
      children: [
        {
          path: "",
          element: (
            <RoutePermissionGate>
              <SubscriptionPackages />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SUBSCRIPTION_PACKAGES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddSubscriptionPackages type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SUBSCRIPTION_PACKAGES + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddSubscriptionPackages type="edit" />
            </RoutePermissionGate>
          ),
        },
      ],
    },

    // Settings
    {
      path: PATHS.SETTINGS,
      element: <Settings />,
      children: [
        {
          path: PATHS.GENERAL_SETTINGS,
          element: (
            <RoutePermissionGate>
              <GeneralSettings />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.EMAIL_NOTIFICATIONS,
          element: (
            <RoutePermissionGate>
              <EmailNotifications />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PAYMENT_GATEWAY,
          element: (
            <RoutePermissionGate>
              <PaymentGateway />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SMTP_SMS_DETAILS,
          element: (
            <RoutePermissionGate>
              <SmtpSmsDetails />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.SOCIAL_MEDIA,
          element: (
            <RoutePermissionGate>
              <SocialMedia />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE_MESSAGES,
          element: (
            <RoutePermissionGate>
              <LanguageMessages />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGUAGE_MESSAGES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguageMessages type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGAUGE_STATIC_LANG,
          element: (
            <RoutePermissionGate>
              <LangaugeStaticLang />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.LANGAUGE_STATIC_LANG + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddLanguageStaticLang type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: (
            <Navigate to={`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`} />
          ),
        },
        {
          path: PATHS.ANY,
          element: (
            <Navigate to={`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`} />
          ),
        },
      ],
    },
    // Settings
    {
      path: PATHS.HOMEPAGECMS,
      element: <Cms />,
      children: [
        {
          path: PATHS.MAIN_BANNER,
          element: (
            <RoutePermissionGate>
              <MainBanner />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PARTNERS,
          element: (
            <RoutePermissionGate>
              <Partners />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PARTNERS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditPartners type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.PARTNERS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditPartners type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BENEFITS,
          element: (
            <RoutePermissionGate>
              <Benefits />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BENEFITS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditBenefits type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.BENEFITS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditBenefits type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.HOW_TO_WORK,
          element: (
            <RoutePermissionGate>
              <HowWeWork />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.HOW_TO_WORK + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditHowWeWork type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.HOW_TO_WORK + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditHowWeWork type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.TESTIMONIALS,
          element: (
            <RoutePermissionGate>
              <Testimonials />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.TESTIMONIALS + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditTestimonials type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.TESTIMONIALS + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditTestimonials type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CAPABILITIES,
          element: (
            <RoutePermissionGate>
              <Capabilities />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CAPABILITIES + "/" + PATHS.CREATE,
          element: (
            <RoutePermissionGate>
              <AddEditCapabilities type="add" />
            </RoutePermissionGate>
          ),
        },
        {
          path: PATHS.CAPABILITIES + "/" + PATHS.EDIT,
          element: (
            <RoutePermissionGate>
              <AddEditCapabilities type="edit" />
            </RoutePermissionGate>
          ),
        },
        {
          path: "",
          element: (
            <Navigate to={`${PATHS.HOMEPAGECMS}/${PATHS.MAIN_BANNER}`} />
          ),
        },
        {
          path: PATHS.ANY,
          element: (
            <Navigate to={`${PATHS.HOMEPAGECMS}/${PATHS.MAIN_BANNER}`} />
          ),
        },
      ],
    },
    // Common Pages
    {
      path: PATHS.FourZeroFour,
      element: <FourZeroFourPage />,
    },
    {
      path: PATHS.THANKYOU,
      element: <ThankyouPage />,
    },
    {
      path: PATHS.COMING_SOON,
      element: <ComingSoonPage />,
    },
    // My Profile
    {
      path: PATHS.MY_PROFILE,
      element: <MyProfile />,
    },
    {
      path: PATHS.EDIT_MY_PROFILE,
      element: <EditMyProfile />,
    },
    {
      path: PATHS.CHANGE_PASSWORD,
      element: <ChangePasswordPage />,
    },
    // UI Kit
    {
      path: PATHS.UI_KIT,
      element: (
        <UiKitLayout>
          <UiKit />
        </UiKitLayout>
      ),
      exact: true,
      children: [
        {
          path: PATHS.TYPOGRAPHY,
          element: <UiTypography />,
        },
        {
          path: PATHS.COLORS,
          element: <UiColors />,
        },
        {
          path: PATHS.FORMS,
          element: <UiForms />,
        },
        {
          path: PATHS.CARDS,
          element: <UiCards />,
        },
        {
          path: PATHS.BLOCKS,
          element: <UiBlocks />,
        },
        {
          path: PATHS.ANY,
          element: <Navigate to={PATHS.UI_KIT} />,
        },
      ],
    },
  ],
};

const AuthenticationRoutes = {
  path: PATHS.DEFAULT,
  element: (
    <GuestRoute>
      <AuthLayout />
    </GuestRoute>
  ),
  children: [
    {
      path: PATHS.DEFAULT,
      element: <Login />,
    },
    // {
    //   path: PATHS.OTP_DETAILS,
    //   element: <OtpDetails />,
    // },
    // {
    //   path: PATHS.OTP_VERIFICATION,
    //   element: <OtpVerification />,
    // },
    {
      path: PATHS.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: PATHS.RESET_PASSWORD,
      element: <ResetPassword />,
    },
  ],
};

/**
 * @params {boolean}
 * @return {Array} of all paths & routes
 */
export const getRoutes = () => [
  AuthenticationRoutes,
  MainRoutes,
  {
    path: PATHS.ANY,
    element: <FourZeroFourPage />,
  },
];
