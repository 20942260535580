import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const roleApi = createApi({
  reducerPath: REDUCER_PATHS.ROLE,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getAllPermission: builder.query({
      query: () => ({
        url: apiConfig.GET_ALL_PERMISSSION,
        method: "GET",
      }),
    }),
    getAllroles: builder.query({
      query: () => ({
        url: apiConfig.ROLE,
        method: "GET",
      }),
    }),
    addRole: builder.mutation({
      query: (data) => ({
        url: apiConfig.ROLE,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["roleDetails", "roleList"],
    }),
    editRole: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ROLE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["roleDetails", "roleList"],
    }),
    roleListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.ROLE_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&searchText=${data?.searchText}`,
        method: "GET",
      }),
    }),
    changeRole: builder.mutation({
      query: (data) => ({
        url: apiConfig.CHANGE_ROLE,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["roleDetails", "roleListing"],
    }),
    deleteRole: builder.mutation({
      query: (data) => ({
        url: apiConfig.ROLE,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    roleDetails: builder.query({
      query: (id) => ({
        url: `${apiConfig.ROLE}/${id}`,
        method: "GET",
      }),
      providesTags: ["roleDetails"],
    }),
    rolesList: builder.query({
      query: () => ({
        url: apiConfig.ROLE,
        method: "GET",
      }),
      providesTags: ["roleList"],
    }),
    userList: builder.query({
      query: (data) => ({
        url: `${apiConfig.USER_LIST}/${data?.id}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}`,
        method: "GET",
      }),
    }),
    roleDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ROLES_DOWNLOAD}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data.filters}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllPermissionQuery,
  useGetAllrolesQuery,
  useAddRoleMutation,
  useLazyRoleListingQuery,
  useChangeRoleMutation,
  useDeleteRoleMutation,
  useLazyRoleDetailsQuery,
  useRolesListQuery,
  useLazyUserListQuery,
  useEditRoleMutation,
  useRoleDetailsDownloadMutation,
} = roleApi;

export default roleApi;
