import AsideMenu from "components/asideMenu/AsideMenu";
import LanguageSelect from "components/languageSelect/LanguageSelect";
import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAuthHook } from "hooks/auth/useAuthHook";
import { useLanguageHook } from "hooks/master/useLanguage";
import { useProfileHook } from "hooks/profile/useProfileHook";
import { useThemeHook } from "hooks/profile/useThemeHook";
import { perimssionsActions } from "store/actions/permissions";
import { logoutUserTokenAction } from "store/actions/token";
import { setUILanguageWithDefault } from "store/actions/ui";
import { rememberMe } from "utils/functions";

import NotificationBox from "./NotificationBox";

function Header({ showBlur }) {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const { logoutUser, resetAuthData, resetLogoutData } = useAuthHook();
  const { languageData } = useLanguageHook();
  const { updateHtmlTag, THEMES } = useThemeHook();
  const { deleteAllnotifications, getNotifications, notificationData } =
    useProfileHook();
  const location = useLocation();

  const logoutHandler = async () => {
    try {
      await logoutUser();
    } catch {
    } finally {
      resetLogoutData();
      resetAuthData();
      rememberMe();
      updateHtmlTag(THEMES.LIGHT);
      dispatch(logoutUserTokenAction());
      dispatch(
        perimssionsActions({
          modules: [],
          formatedModulesData: {},
          slug: "",
          id: "",
          role: "",
          isModuleLoading: true,
        })
      );
    }
  };

  // Adding class to body element when sidebar toggles
  const [floatingSideBar, setFloatingSideBar] = useState(true);
  const handleToggle = () => {
    if (floatingSideBar) {
      setFloatingSideBar(false);
      document.body.classList.add("sidebar-collapsed");
    } else {
      setFloatingSideBar(true);
      document.body.classList.remove("sidebar-collapsed");
    }
  };
  /*****
  @purpose : Remove side bar collapsed class when aside backdrop div is called
  @Parameter : {}
  @Author : INIC
 */
  const handleSidebarClose = () => {
    if (document?.body?.classList.contains("sidebar-collapsed")) {
      setFloatingSideBar(true);
      document.body.classList.remove("sidebar-collapsed");
    }
  };
  /** ***************** 
  @purpose : Used for language data api call to set default language id in redux store
  @Parameter : {}
  @Author : INIC
  ***************** */
  useEffect(() => {
    if (languageData?.data) {
      const defaultLanguageId =
        languageData?.data?.find((data) => data?.isPrimary)?.id || "";
      dispatch(setUILanguageWithDefault(defaultLanguageId));
    }
  }, [languageData]);

  /** ***************** 
  @purpose : Used for fetching notifications
  @Parameter : {}
  @Author : INIC
  ***************** */

  useEffect(() => {
    const handleRouteChange = () => {
      getNotifications();
    };
    handleRouteChange();
  }, [location]);

  const handleClear = async () => {
    const list = [];
    notificationData?.data?.data.map((item) => {
      list.push(item.id);
      return item;
    });
    const obj = {
      ids: list,
    };
    if (list.length) {
      deleteAllnotifications(obj);
      setToggle(false);
    }
  };

  return (
    <>
      <header className={`global-header${showBlur ? " show-blur" : ""}`}>
        <div className="toggle-sidebar">
          <a onClick={() => handleToggle()}>
            <span className="inic inic-menu"></span>
          </a>
        </div>
        <div className="nav-right">
          <Nav className="nav-menus align-items-center">
            {/* Language select : Start */}
            <LanguageSelect />
            {/* Language select : End */}

            <Nav.Item
              className={
                notificationData?.data?.unReadCount && "new-notification"
              }
            >
              <Nav.Link className="link-dark">
                <span
                  className="inic inic-notifications-active"
                  onClick={() => setToggle(!toggle)}
                ></span>
              </Nav.Link>
              {/* Call this component on click */}
              {/* <NotificationBox /> */}
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="link-danger">
                <span
                  className="inic inic-power-off"
                  onClick={logoutHandler}
                ></span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </header>
      <AsideMenu showBlur={showBlur} handleSidebarClose={handleSidebarClose} />
      {toggle && (
        <NotificationBox
          notificationList={notificationData?.data?.data}
          onClear={handleClear}
        />
      )}
    </>
  );
}

export default Header;
