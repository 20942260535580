import SuspenseLoader from "lib/suspenseLoader/SuspenseLoader";
import React, { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { PATHS } from "configs/routeConfig";

// Defined scopes. These scopes are related to crud actions.
// Naming convention as per data from backend.
// Update when any data is changed from backend api
export const AccessScopes = {
  CREATE: "Create",
  EDIT: "Update",
  VIEW: "View",
  DELETE: "Delete",
  DOWNLOADS: "Download",
  IMPORT: "Import",
  CALENDER: "Calender",
  CONFIGURATION: "Configuration",
  CHANGE: "Change Status",
  MERGE: "Merge",
};

// Permission scopes. these scopes are related to module.
// Names are used as per the backend api role category.
// Add/update/remove any scope as per new module added
// or any module category updated from backedn or any module deleted.
export const PERMISSION_SCOPES = {
  Profile: "Profile",

  Admin_User: "Admin Users",

  Clients: "Clients",

  Demo_Users: "Demo Users",

  Expire_Demos: "Expired Demo Users",

  Demo_Request: "Demo Requests",

  Booking_Meeting: "Meetings",

  Master: "Masters",

  Subscription: "Subscription",

  Static_pages: "Static Pages",

  Email_templetes: "Email Template",

  Role: "Role",

  Email_Template: "Email Template",

  Settings: "Settings",

  Portfolio_user: "Portfolio Users",

  User: "User",

  Dashboard: "Dashboard",

  Admin_Roles: "Admin Roles",

  General_Settings: "General Settings",

  Social_Media_Settings: "Social Media Settings",

  Payment_Gateway_Settings: "Payment Gateway Settings",

  SMTP_SMS_Settings: "SMTP/SMS Settings",

  Email_Notifications_Settings: "Email Notifications Settings",

  Static_Label_Settings: "Static Label Settings",

  // Clients_Dashboard: "Clients Dashboard",
  // Client_Details: "Client Details",
  // History: "History",

  // CMS_Pages: "CMS Pages",
  // Users: "Users",

  // Demo_Calender: "Demo Calender",
  // Demo_Configuration: "Demo Configuration",

  // Meeting_Calender: "Meeting Calender",
  // Meeting_Configuration: "Meeting Configuration",

  // Content: "Content",
  // Blog: "blog",
  // Faq: "faq",
  // Support: "Support",
  // Common_Pages: "Common Pages",
};

// Route scope. map url route with permission scopes
// Add/update/delete when any new route is added or any route is modified
export const routeScopes = {
  // Dashboard
  [PATHS.DASHBOARD]: { scope: PERMISSION_SCOPES.Dashboard },
  // User
  [`${PATHS.USER}/${PATHS.USER_LIST}`]: {
    scope: PERMISSION_SCOPES.User,
  },
  // Clients
  [`${PATHS.USER}/${PATHS.CLIENTSDASHBOARD}`]: {
    scope: PERMISSION_SCOPES.Clients,
  },
  // Clients
  [`${PATHS.USER}/${PATHS.CLIENTS}`]: {
    scope: PERMISSION_SCOPES.Clients,
  },
  // Client Details
  [`${PATHS.USER}/${PATHS.CLIENT_DETAILS}`]: {
    scope: PERMISSION_SCOPES.Client_Details,
  },
  // History
  [`${PATHS.USER}/${PATHS.HISTORY}`]: {
    scope: PERMISSION_SCOPES.History,
  },
  // Admin user
  [`${PATHS.USER}/${PATHS.ADMIN_USER}`]: {
    scope: PERMISSION_SCOPES.Admin_User,
  },
  // Admin Roles
  [`${PATHS.USER}/${PATHS.PORTFOLIO_USERS}`]: {
    scope: PERMISSION_SCOPES.Portfolio_user,
  },
  // Admin Roles
  [`${PATHS.USER}/${PATHS.ADMIN_ROLES}`]: {
    scope: PERMISSION_SCOPES.Admin_Roles,
  },
  // Demos
  [`${PATHS.DEMOS}/${PATHS.DEMOS}`]: {
    scope: PERMISSION_SCOPES.Demos,
  },
  // Access Demos Request
  [`${PATHS.DEMOS}/${PATHS.DEMO_REQUEST}`]: {
    scope: PERMISSION_SCOPES.Demo_Request,
  },
  // Demos-Calender
  [`${PATHS.DEMOS}/${PATHS.SCHEDULE_DEMO}`]: {
    scope: PERMISSION_SCOPES.Demo_Calender,
  },
  // Demos-Configuration
  [`${PATHS.DEMOS}/${PATHS.DEMO_CONFIGURATION}`]: {
    scope: PERMISSION_SCOPES.Demo_Configuration,
  },
  // Booking Meeting
  [`${PATHS.DEMOS}/${PATHS.BOOKED_MEETING}`]: {
    scope: PERMISSION_SCOPES.Booking_Meeting,
  },
  // Meeting-Calender
  [`${PATHS.DEMOS}/${PATHS.SCHEDULE_MEETING}`]: {
    scope: PERMISSION_SCOPES.Meeting_Calender,
  },
  // Meeting-Configuration
  [`${PATHS.DEMOS}/${PATHS.MEETING_CONFIGURATION}`]: {
    scope: PERMISSION_SCOPES.Meeting_Configuration,
  },
  // Demos Users
  [`${PATHS.DEMOS}/${PATHS.DEMO_USERS}`]: {
    scope: PERMISSION_SCOPES.Demo_Users,
  },

  // Static pages
  [`${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`]: {
    scope: PERMISSION_SCOPES.Static_pages,
  },

  // Email templetes
  [`${PATHS.CONTENT}/${PATHS.EMAIL_TEMPLATES}`]: {
    scope: PERMISSION_SCOPES.Email_templetes,
  },
  // // Blogs
  // [`${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_LIST}`]: {
  //   scope: PERMISSION_SCOPES.Blog,
  // },
  // [`${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_CATEGORY}`]: {
  //   scope: PERMISSION_SCOPES.Blog,
  // },
  // //Faqs
  // [`${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_LIST}`]: {
  //   scope: PERMISSION_SCOPES.Faq,
  // },
  // [`${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_CATEGORY}`]: {
  //   scope: PERMISSION_SCOPES.Faq,
  // },
  // Master
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.TIMEZONE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.LANGUAGE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATIONSIZE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.DOMAIN_OF_WORK}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATION_GOAL}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATION_DEVICE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.REFERRAL_SOURCE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.CIVIL_STATUS}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.CONTRACT_TYPE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.JOB_POSITION}`]: {
    scope: PERMISSION_SCOPES.Master,
  },
  [`${PATHS.MASTER_MANAGEMENT}/${PATHS.EMPLOYEE_TYPE}`]: {
    scope: PERMISSION_SCOPES.Master,
  },

  // Email Templates
  [PATHS.EMAIL_TEMPLATES]: { scope: PERMISSION_SCOPES.Email_Template },
  // Support
  [PATHS.SUPPORT]: { scope: PERMISSION_SCOPES.Support },
  // Settings
  [`${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`]: {
    scope: PERMISSION_SCOPES.General_Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.SOCIAL_MEDIA}`]: {
    scope: PERMISSION_SCOPES.Social_Media_Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.PAYMENT_GATEWAY}`]: {
    scope: PERMISSION_SCOPES.Payment_Gateway_Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.SMTP_SMS_DETAILS}`]: {
    scope: PERMISSION_SCOPES.SMTP_SMS_Settings,
  },
  [`${PATHS.SETTINGS}/${PATHS.EMAIL_NOTIFICATIONS}`]: {
    scope: PERMISSION_SCOPES.Email_Notifications_Settings,
  },
  // [`${PATHS.SETTINGS}/${PATHS.LANGUAGE_MESSAGES}`]: {
  //   scope: PERMISSION_SCOPES.Settings,
  // },
  [`${PATHS.SETTINGS}/${PATHS.LANGAUGE_STATIC_LANG}`]: {
    scope: PERMISSION_SCOPES.Static_Label_Settings,
  },
  // ROle
  [PATHS.ROLE]: {
    scope: PERMISSION_SCOPES.Role,
  },
  // Profile
  [PATHS.MY_PROFILE]: {
    scope: PERMISSION_SCOPES.Profile,
  },
  [PATHS.EDIT_MY_PROFILE]: {
    scope: PERMISSION_SCOPES.Profile,
  },
};

/******************* 
  @purpose :Used for checking aside menu link permission
  @Author : INIC
  ******************/
/******************* 
  @purpose :Used for checking aside menu link permission
  @Author : INIC
  ******************/
export function ModulePermissionGate({ children, scope }) {
  const { modules, slug } = useSelector(function (state) {
    return state.permissions;
  }, shallowEqual);
  const hasPermission = useMemo(
    function () {
      return (
        slug === "super_admin" ||
        !!modules?.find(function (module) {
          return (
            scope === true ||
            module.category === scope ||
            (scope && scope.includes(module.category))
          );
        })
      );
    },
    [modules, slug, scope]
  );
  if (hasPermission) {
    return children;
  }
  return null;
}

export const formatUrlPathScopes = (pathname, params) => {
  // remove any params from url
  const pathWithoutParams = Object.values(params).reduce(
    (path, param) => path.replace("/" + param, ""),
    pathname
  );
  // get crud scope from url (i.e view/create/edit)
  const pathScope = Object.values(AccessScopes).find((scope) => {
    return pathWithoutParams.indexOf(scope) != -1;
  });
  // get url module path
  const pathWithoutScope = pathScope
    ? pathWithoutParams.replace("/" + pathScope.toLowerCase(), "")
    : pathWithoutParams;

  return { pathWithoutParams, pathScope, pathWithoutScope };
};

/******************* 
  @purpose :Used for checking route permission.
            Checks any url entered in browser if it has permission
  @Author : INIC
  ******************/
export function RoutePermissionGate({ children }) {
  const { modules, isModuleLoading, slug } = useSelector(
    (state) => state.permissions,
    shallowEqual
  );
  const { pathname } = useLocation();
  const params = useParams();

  const {
    hasModulePermission,
    pathScope,
    pathWithoutScope,
    hasScopePermission,
  } = useMemo(() => {
    // return without checking if user is super admin or api is loading
    if (isModuleLoading || slug === "super_admin") {
      return {};
    }
    const { pathWithoutParams, pathScope, pathWithoutScope } =
      formatUrlPathScopes(pathname, params);
    const currentModule = modules?.find(
      (module) => module.category === routeScopes[pathWithoutScope]?.scope
    );
    let currentScope = false;

    if (pathScope) {
      currentScope = currentModule?.permissions?.find(
        (mod) => mod.permissionKey === `${pathScope}` //`${pathScope} ${currentModule.category}`
      )?.isSelected;
    }

    return {
      hasModulePermission: !!currentModule,
      hasScopePermission: !!currentScope,
      pathWithoutScope,
      pathWithoutParams,
      pathScope,
    };
  }, [modules, pathname]);

  if (isModuleLoading) {
    return <SuspenseLoader />;
  }

  // if role is super admin return children without checking
  if (slug === "super_admin") {
    return children;
  }

  // if user doesnot have permission naviagte to dashboard
  // if (!hasModulePermission && pathWithoutScope !== PATHS.DASHBOARD) {
  //   return <Navigate to={PATHS.DASHBOARD} />;
  // }

  // if user have module permission but doesnot have crud operation permission
  // then navigate to back to module
  if (pathScope && hasModulePermission && !hasScopePermission) {
    return <Navigate to={pathWithoutScope} replace />;
  }

  return children;
}

export function ScopePermissionGate({ children, scope, module }) {
  const { isModuleLoading, slug, formatedModulesData } = useSelector(
    (state) => state.permissions,
    shallowEqual
  );
  const hasPermission = useMemo(
    () =>
      slug !== "super_admin" &&
      !!(
        formatedModulesData?.[module]?.[scope] ||
        formatedModulesData?.[module]?.[`${scope} ${module}`] ||
        formatedModulesData?.[module]?.allScope
      ),
    []
  );

  if (isModuleLoading) {
    return <></>;
  }

  // if role is super admin return children without checking
  if (slug === "super_admin") {
    return children;
  }

  if (!hasPermission) {
    return <></>;
  }

  return children;
}
