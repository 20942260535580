import { combineReducers } from "@reduxjs/toolkit";

import authApi from "store/api/auth";
import cmsApi from "store/api/cms";
import filterAPI from "store/api/common";
import blogApi from "store/api/content";
import demoApi from "store/api/demo";
import emailTemplatesApi from "store/api/emailTemplates";
import masterApi from "store/api/master";
import profileApi from "store/api/profile";
import roleApi from "store/api/role";
import settingsApi from "store/api/settings";
import subscriptionApi from "store/api/subscription";
import supportApi from "store/api/support";
import userApi from "store/api/users";
import userTokenReducer from "store/reducers/token";
import uiReducer from "store/reducers/ui";

import permissionsReducer from "./permissions";
/** ***************** 
@purpose : root reducer
@Author : INIC
@return : combine reducers
***************** */

export default () =>
  combineReducers({
    ui: uiReducer,
    token: userTokenReducer,
    permissions: permissionsReducer,
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [masterApi.reducerPath]: masterApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [filterAPI.reducerPath]: filterAPI.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
    [demoApi.reducerPath]: demoApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  });

/** ***************** 
@purpose : RTK Query middlwares
@Author : INIC
@return : array of middlewares
***************** */
export const getReducerMiddlewares = () => [
  authApi.middleware,
  profileApi.middleware,
  settingsApi.middleware,
  userApi.middleware,
  masterApi.middleware,
  roleApi.middleware,
  emailTemplatesApi.middleware,
  blogApi.middleware,
  filterAPI.middleware,
  supportApi.middleware,
  cmsApi.middleware,
  demoApi.middleware,
  subscriptionApi.middleware,
];
