import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import BaseRoutes from "routes/Routes";

import { useThemeHook } from "hooks/profile/useThemeHook";

function App() {
  const { setInitialTheme } = useThemeHook();
  useEffect(setInitialTheme, []);
  return (
    <div className="App">
      <BaseRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
