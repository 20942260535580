import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const masterApi = createApi({
  reducerPath: REDUCER_PATHS.MASTER,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTimeZone: builder.query({
      query: () => ({
        url: apiConfig.TIMEZONE,
        method: "GET",
      }),
      providesTags: ["Timezone"],
    }),

    timezoneList: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE_LIST,
        method: "POST",
        body: data,
      }),
    }),
    timezoneDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        method: "DELETE",
        body: data,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addTimezone: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Timezone"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    timezoneChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.TIMEZONE,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    // country
    getCountry: builder.query({
      query: (data) => ({
        url: `${apiConfig.COUNTRY_LIST}?languageId=${data?.languageId}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["country"],
    }),
    countryList: builder.query({
      query: (data) => ({
        url: `${apiConfig.COUNTRY}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["countryListing"],
    }),
    countryListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    countryListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addCountryList: builder.mutation({
      query: (data) => ({
        url: apiConfig.COUNTRY,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Country", "countryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getCurrency: builder.query({
      query: () => ({
        url: apiConfig.GET_CURRENCY_LIST,
        method: "GET",
      }),
      providesTags: ["getCurrency"],
    }),
    currencyList: builder.query({
      query: (data) => ({
        url: `${apiConfig.CURRENCY_LIST}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["Currency"],
    }),
    currencyListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.CURRENCY_LIST,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Currency"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    currencyListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CURRENCY_LIST,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Currency"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addCurrencyList: builder.mutation({
      query: (data) => ({
        url: apiConfig.CURRENCY_LIST,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Currency"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    // industry
    getIndustry: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_INDUSTRY}?languageId=${data?.languageId}&filters=${data?.filters}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["Industry"],
    }),

    industryList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.INDUSTRY}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}&filters=${data.filters}`,
        method: "GET",
      }),
      providesTags: ["industryListing"],
    }),
    industryListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.INDUSTRY,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Industry", "industryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    industryListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.INDUSTRY,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Industry", "industryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addIndustryList: builder.mutation({
      query: (data) => ({
        url: apiConfig.INDUSTRY,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["industryDetails", "industryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editIndustryList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.INDUSTRY}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["industryDetails", "industryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    industryDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.INDUSTRY_DETAILS}?industryCode=${data.industryCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["industryDetails"],
    }),
    IndustrtDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.INDUSTRY_DETAILS_DOWNLOADS}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data.searchText}&filters=${data.filters}`,
        method: "GET",
      }),
    }),

    // OrganizationSize
    getOrganizationSize: builder.query({
      query: () => ({
        url: apiConfig.GET_ORGANIZATION_SIZE,
        method: "GET",
      }),
      providesTags: ["OrganizationSize"],
    }),

    organizationSizeList: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_SIZE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&filters=${data.filters}`,
        method: "GET",
      }),
      providesTags: ["OrganizationSize"],
    }),
    organizationSizeListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_SIZE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["OrganizationSize", "organizationSizeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationSizeListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_SIZE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["OrganizationSize", "organizationSizeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addOrganizationSizeList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_SIZE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OrganizationSize", "organizationSizeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editOrganizationSizeList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_SIZE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["organizationSizeDetails", "organizationSizeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationSizeDetails: builder.query({
      query: (id) => ({
        url: `${apiConfig.ORGANIZATION_SIZE}/${id}`,
        method: "GET",
      }),
      providesTags: ["organizationSizeDetails"],
    }),
    organizationSizeDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_DETAILS_DOWNLOADS}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data.searchText}&filters=${data.filters}`,
        method: "GET",
      }),
    }),

    // Language
    getLanguage: builder.query({
      query: () => ({
        url: apiConfig.GET_LANGUAGE,
        method: "GET",
      }),
      providesTags: ["Language"],
    }),

    languageList: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["languageListing"],
    }),
    languageListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Language", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    languageListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Language", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addLanguageList: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["languageDetails", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editLanguageList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}`,
        body: data?.postData,
        method: "POST",
      }),
      invalidatesTags: ["languageDetails", "languageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    languageDetails: builder.query({
      query: (id) => ({
        url: `${apiConfig.LANGUAGE}/${id}`,
        method: "GET",
      }),
      providesTags: ["languageDetails"],
    }),

    // Domain Of Work
    getWork: builder.query({
      query: () => ({
        url: `${apiConfig.GET_DOMAIN_OF_WORK}?languageId=${data?.languageId}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["Work"],
    }),

    workList: builder.query({
      query: (data) => ({
        url: `${apiConfig.DOMAIN_OF_WORK}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}&filters=${data.filters}`,
        method: "GET",
      }),
      providesTags: ["work", "WorkListing"],
    }),
    workListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.DOMAIN_OF_WORK,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["work", "workListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    workListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.DOMAIN_OF_WORK,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["work", "workListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addWorkList: builder.mutation({
      query: (data) => ({
        url: apiConfig.DOMAIN_OF_WORK,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["work", "WorkListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editWorkList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DOMAIN_OF_WORK}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["work", "workListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    workDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.DOMAIN_OF_WORK_DETAILS}?title=${data.title}&languageId=${data.languageId}&dowCode=${data.dowCode}`,
        method: "GET",
      }),
      providesTags: ["work", "workListing"],
    }),
    workDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DOMAIN_OF_WORK_DETAILS_DOWNLOADS}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data.searchText}&filters=${data.filters}`,
        method: "GET",
      }),
    }),

    // Means of Transportation
    getTransportation: builder.query({
      query: () => ({
        url: apiConfig.GET_LANGUAGE,
        method: "GET",
      }),
      providesTags: ["Transportation"],
    }),

    transportationList: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["Transportation"],
    }),
    transportationListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Transportation", "transportationListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    transportationListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Transportation", "transportationListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addTransportationList: builder.mutation({
      query: (data) => ({
        url: apiConfig.LANGUAGE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["transportationDetails", "transportationListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editTransportationList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["transportationDetails", "transportationListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    transportationDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.LANGUAGE}?title=${data.title}&languageCode=${data.languageCode}`,
        method: "GET",
      }),
      providesTags: ["transportationDetails"],
    }),

    // Organization Goals
    getOrganizationGoal: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_ORGANIZATION_GOAL}?languageId=${data?.languageId}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["OrganizationGoal"],
    }),

    organizationGoalList: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_GOAL}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["OrganizationGoal"],
    }),
    organizationGoalListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_GOAL,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["OrganizationGoal", "organizationGoalListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationGoalListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_GOAL,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["OrganizationGoal", "organizationGoalListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addOrganizationGoalList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_GOAL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OrganizationGoal", "organizationGoalListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editOrganizationGoalList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_GOAL}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["OrganizationGoal", "organizationGoalListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationGoalDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_GOAL_DETAILS}?goalCode=${data.goalCode}&goalName=${data.goalName}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["organizationGoalDetails"],
    }),

    // Organization Devices
    getOrganizationDevice: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_ORGANIZATION_DEVICE}?languageId=${data?.languageId}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["OrganizationDevice"],
    }),

    organizationDeviceList: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_DEVICE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["organizationDevice"],
    }),
    organizationDeviceListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_DEVICE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["organizationDevice", "organizationDeviceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationDeviceListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_DEVICE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["organizationDevice", "organizationDeviceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addOrganizationDeviceList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ORGANIZATION_DEVICE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["organizationDevice", "organizationDeviceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editOrganizationDeviceList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_DEVICE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["organizationDevice", "organizationDeviceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    organizationDeviceDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.ORGANIZATION_DEVICE_DETAILS}?deviceCode=${data.deviceCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["organizationDevice"],
    }),

    // Referral Source
    getReferralSource: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_REFERRAL_SOURCE}?languageId=${data?.languageId}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["referralSource"],
    }),

    referralSourceList: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["referralSource"],
    }),
    referralSourceListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["referralSource", "referralSourceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    referralSourceListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["referralSource", "referralSourceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addReferralSourceList: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["referralSource", "referralSourceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editReferralSourceList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_REFERRAL_SOURCE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["referralSource", "referralSourceListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    referralSourceDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE_DETAILS}?sourceName=${data.sourceName}&sourceCode=${data.sourceCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["referralSource"],
    }),
    referalSourceDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE_DETAILS_DOWNLOADS}?page=${data.page}&pageSize=${data.pageSize}&languageId=${data?.languageId}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data.searchText}`,
        method: "GET",
      }),
    }),

    // Civil Status
    // getCivilStatus: builder.query({
    //   query: () => ({
    //     url: apiConfig.GET_CIVIL_STATUS,
    //     method: "GET",
    //   }),
    //   providesTags: ["CivilStatus"],
    // }),

    civilStatusList: builder.query({
      query: (data) => ({
        url: `${apiConfig.CIVIL_STATUS}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["civilStatus"],
    }),
    civilStatusListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CIVIL_STATUS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["civilStatus", "civilStatusListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    civilStatusListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.CIVIL_STATUS,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["civilStatus", "civilStatusListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addCivilStatusList: builder.mutation({
      query: (data) => ({
        url: apiConfig.CIVIL_STATUS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["civilStatus", "civilStatusListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editCivilStatusList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CIVIL_STATUS}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["civilStatus", "civilDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    civilStatusDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.CIVIL_STATUS_DETAILS}?civilCode=${data.civilCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["civilDetails", "civilStatus"],
    }),

    // Contract Type
    getContractType: builder.query({
      query: () => ({
        url: apiConfig.GET_CONTRACT_TYPE,
        method: "GET",
      }),
      providesTags: ["contractType"],
    }),

    contractTypeList: builder.query({
      query: (data) => ({
        url: `${apiConfig.CONTRACT_TYPE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["contractType"],
    }),
    contractTypeListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CONTRACT_TYPE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["contractType", "contractTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    contractTypeListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.CONTRACT_TYPE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["contractType", "contractTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addContractTypeList: builder.mutation({
      query: (data) => ({
        url: apiConfig.CONTRACT_TYPE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contractType", "contractTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editContractTypeList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CONTRACT_TYPE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["contractType", "contractTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    contractTypeDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.CONTRACT_TYPE_DETAILS}?contractCode=${data.contractCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["contractType"],
    }),

    //Work Arrangement
    getWorkArrangement: builder.query({
      query: () => ({
        url: apiConfig.GET_WORK_ARRANGEMENT,
        method: "GET",
      }),
      providesTags: ["WorkArrangement"],
    }),

    workArrangementList: builder.query({
      query: (data) => ({
        url: `${apiConfig.WORK_ARRANGEMENT}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["WorkArrangement"],
    }),
    workArrangementListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.WORK_ARRANGEMENT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["WorkArrangement", "workArrangementListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    workArrangementListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.WORK_ARRANGEMENT,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["WorkArrangement", "workArrangementListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addWorkArrangementList: builder.mutation({
      query: (data) => ({
        url: apiConfig.WORK_ARRANGEMENT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["WorkArrangement", "workArrangementListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editWorkArrangementList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_WORK_ARRANGEMENT}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["WorkArrangement", "workArrangementListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    workArrangementDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.WORK_ARRANGEMENT_DETAILS}?workArrangementCode=${data.workArrangementCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["workArrangementDetails", "WorkArrangement"],
    }),

    //Breaks Name
    getBreaksName: builder.query({
      query: () => ({
        url: apiConfig.GET_REFERRAL_SOURCE,
        method: "GET",
      }),
      providesTags: ["BreaksName"],
    }),

    BreaksNameList: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["BreaksName"],
    }),
    breaksNameListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["BreaksName", "breaksNameListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    breaksNameListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["BreaksName", "breaksNameListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addBreaksNameList: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["breaksNameDetails", "breaksNameListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editBreaksNameList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["breaksNameDetails", "breaksNameListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    breaksNameDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE_DETAILS}?sourceName=${data.sourceName}&sourceCode=${data.sourceCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["breaksNameDetails"],
    }),

    //Deductions
    getDeductions: builder.query({
      query: () => ({
        url: apiConfig.GET_REFERRAL_SOURCE,
        method: "GET",
      }),
      providesTags: ["Deductions"],
    }),

    deductionsList: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["Deductions"],
    }),
    deductionsListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Deductions", "deductionsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deductionsListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Deductions", "deductionsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addDeductionsList: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deductionsDetails", "deductionsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editDeductionsList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["deductionsDetails", "deductionsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deductionsDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.REFERRAL_SOURCE_DETAILS}?sourceName=${data.sourceName}&sourceCode=${data.sourceCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["deductionsDetails"],
    }),

    //Job Position
    // getJobPosition: builder.query({
    //   query: () => ({
    //     url: apiConfig.GET_JOB_POSITION,
    //     method: "GET",
    //   }),
    //   providesTags: ["jobPosition"],
    // }),

    jobPositionList: builder.query({
      query: (data) => ({
        url: `${apiConfig.JOB_POSITION}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["jobPosition"],
    }),
    jobPositionListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.JOB_POSITION,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["jobPosition", "jobPositionListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    jobPositionListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.JOB_POSITION,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["jobPosition", "jobPositionListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addJobPositionList: builder.mutation({
      query: (data) => ({
        url: apiConfig.JOB_POSITION,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["jobPosition", "jobPositionListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editJobPositionList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_JOB_POSITION}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["jobPosition", "jobPositionListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    jobPositionDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.JOB_POSITION_DETAILS}?jobCode=${data.jobCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["jobPositionDetails", "jobPosition"],
    }),

    //employee Type
    // getEmployeeType: builder.query({
    //   query: () => ({
    //     url: apiConfig.GET_EMPLOYEE_TYPE,
    //     method: "GET",
    //   }),
    //   providesTags: ["employeeType"],
    // }),

    employeeTypeList: builder.query({
      query: (data) => ({
        url: `${apiConfig.EMPLOYEE_TYPE}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["employeeType"],
    }),
    employeeTypeListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.EMPLOYEE_TYPE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["employeeType", "employeeTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    employeeTypeListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.EMPLOYEE_TYPE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["employeeType", "employeeTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addEmployeeTypeList: builder.mutation({
      query: (data) => ({
        url: apiConfig.EMPLOYEE_TYPE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["employeeType", "employeeTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editEmployeeTypeList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_EMPLOYEE_TYPE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["employeeType", "employeeTypeListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    employeeTypeDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.EMPLOYEE_TYPE_DETAILS}?typeCode=${data.typeCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["employeeTypeDetails", "employeeType"],
    }),
  }),
});

export const {
  useGetTimeZoneQuery,
  useTimezoneListMutation,
  useTimezoneDeleteMutation,
  useAddTimezoneMutation,
  // useGetCountryQuery,
  useLazyGetCountryQuery,
  useTimezoneChangeStatusMutation,
  useLazyCountryListQuery,
  useCountryListDeleteMutation,
  useCountryListChangeStatusMutation,
  useAddCountryListMutation,

  // useGetCurrencyQuery,
  useLazyGetCurrencyQuery,
  useLazyCurrencyListQuery,
  useCurrencyListDeleteMutation,
  useCurrencyListChangeStatusMutation,
  useAddCurrencyListMutation,

  useGetLanguageQuery,
  useLazyLanguageListQuery,
  useLanguageListChangeStatusMutation,
  useLanguageListDeleteMutation,
  useAddLanguageListMutation,
  useEditLanguageListMutation,
  useLazyLanguageDetailsQuery,
  // useGetIndustryQuery,
  useLazyGetIndustryQuery,
  useLazyIndustryListQuery,
  useIndustryListChangeStatusMutation,
  useIndustryListDeleteMutation,
  useAddIndustryListMutation,
  useEditIndustryListMutation,
  useLazyIndustryDetailsQuery,
  useIndustrtDetailsDownloadMutation,
  useGetOrganizationSizeQuery,
  useLazyOrganizationSizeListQuery,
  useOrganizationSizeListChangeStatusMutation,
  useOrganizationSizeListDeleteMutation,
  useAddOrganizationSizeListMutation,
  useEditOrganizationSizeListMutation,
  useLazyOrganizationSizeDetailsQuery,
  // useGetWorkQuery,
  useLazyGetWorkQuery,
  useLazyWorkListQuery,
  useWorkListChangeStatusMutation,
  useWorkListDeleteMutation,
  useAddWorkListMutation,
  useEditWorkListMutation,
  useLazyWorkDetailsQuery,
  useWorkDetailsDownloadMutation,
  useGetTransportationQuery,
  useLazyTransportationListQuery,
  useTransportationListChangeStatusMutation,
  useTransportationListDeleteMutation,
  useAddTransportationListMutation,
  useEditTransportationListMutation,
  useLazyTransportationDetailsQuery,

  // useGetOrganizationGoalQuery,
  useLazyGetOrganizationGoalQuery,
  useLazyOrganizationGoalListQuery,
  useOrganizationGoalListChangeStatusMutation,
  useOrganizationGoalListDeleteMutation,
  useAddOrganizationGoalListMutation,
  useEditOrganizationGoalListMutation,
  useLazyOrganizationGoalDetailsQuery,
  useOrganizationSizeDetailsDownloadMutation,

  // useGetOrganizationDeviceQuery,
  useLazyGetOrganizationDeviceQuery,
  useLazyOrganizationDeviceListQuery,
  useOrganizationDeviceListChangeStatusMutation,
  useOrganizationDeviceListDeleteMutation,
  useAddOrganizationDeviceListMutation,
  useEditOrganizationDeviceListMutation,
  useLazyOrganizationDeviceDetailsQuery,

  // useGetReferralSourceQuery,
  useLazyGetReferralSourceQuery,
  useLazyReferralSourceListQuery,
  useReferralSourceListChangeStatusMutation,
  useReferralSourceListDeleteMutation,
  useAddReferralSourceListMutation,
  useEditReferralSourceListMutation,
  useLazyReferralSourceDetailsQuery,
  useReferalSourceDetailsDownloadMutation,

  // useGetCivilStatusQuery,
  useLazyCivilStatusListQuery,
  useCivilStatusListChangeStatusMutation,
  useCivilStatusListDeleteMutation,
  useAddCivilStatusListMutation,
  useEditCivilStatusListMutation,
  useLazyCivilStatusDetailsQuery,

  useGetContractTypeQuery,
  useLazyContractTypeListQuery,
  useContractTypeListChangeStatusMutation,
  useContractTypeListDeleteMutation,
  useAddContractTypeListMutation,
  useEditContractTypeListMutation,
  useLazyContractTypeDetailsQuery,

  useGetWorkArrangementQuery,
  useLazyWorkArrangementListQuery,
  useWorkArrangementListChangeStatusMutation,
  useWorkArrangementListDeleteMutation,
  useAddWorkArrangementListMutation,
  useEditWorkArrangementListMutation,
  useLazyWorkArrangementDetailsQuery,

  useGetBreaksNameQuery,
  useLazyBreaksNameListQuery,
  useBreaksNameListChangeStatusMutation,
  useBreaksNameListDeleteMutation,
  useAddBreaksNameListMutation,
  useEditBreaksNameListMutation,
  useLazyBreaksNameDetailsQuery,

  useGetDeductionsQuery,
  useLazyDeductionsListQuery,
  useDeductionsListChangeStatusMutation,
  useDeductionsListDeleteMutation,
  useAddDeductionsListMutation,
  useEditDeductionsListMutation,
  useLazyDeductionsDetailsQuery,

  // useGetJobPositionQuery,
  useLazyJobPositionListQuery,
  useJobPositionListChangeStatusMutation,
  useJobPositionListDeleteMutation,
  useAddJobPositionListMutation,
  useEditJobPositionListMutation,
  useLazyJobPositionDetailsQuery,

  //  useGetEmployeeTypeQuery,
  useLazyEmployeeTypeListQuery,
  useEmployeeTypeListChangeStatusMutation,
  useEmployeeTypeListDeleteMutation,
  useAddEmployeeTypeListMutation,
  useEditEmployeeTypeListMutation,
  useLazyEmployeeTypeDetailsQuery,
} = masterApi;

export default masterApi;
