import React from "react";
import Select from "react-select";

const languageOptions = [
  { value: "en", label: "English", flag: "🇺🇸" },
  { value: "fr", label: "French", flag: "🇫🇷" },
  { value: "ar", label: "Arabic", flag: "🇸🇦" },
  // Add more language options as needed
];

const defaultLanguage = languageOptions[0];

const LanguageSelect = () => {
  return (
    <Select
      className="language-dropdown"
      classNamePrefix="language-select"
      options={languageOptions}
      //  onChange={handleChange}
      defaultValue={defaultLanguage}
      isSearchable={false}
      formatOptionLabel={({ label, flag }) => (
        <div className="selected-country">
          <span className="country-flag">{flag}</span>
          <span className="language-title">{label}</span>
        </div>
      )}
    />
  );
};

export default LanguageSelect;
