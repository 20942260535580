import { createReducer } from "@reduxjs/toolkit";

import {
  setUILanguage,
  setUILanguageWithDefault,
  setUiKey,
  toggleLoader,
  toggleUiModal,
} from "store/actions";

/** ***************** 
@purpose : Intital ui reducer data
@Author : INIC
***************** */
const initialState = {
  isLoading: false,
  messages: [],
  modals: {},
  loaders: {
    page: false,
  },
  defaultLanguage: "",
  language: {
    master: "",
    users: "",
    adminUsers: "",
    static: "",
    blogs: "",
    blogCategory: "",
    faq: "",
    faqCategory: "",
    siteSettings: "",
    metaData: "",
    siteMaintainance: "",
    emailTemplate: "",
    mainBanner: "",
    mobileMainBanner: "",
    Benifits: "",
    Capabilities: "",
    Partners: "",
    hw: "",
    Testimonials: "",
  },
};

/** ***************** 
  @purpose : UI Reducer
  @Parameter : {ui_ini_data, action}
  @Author : INIC
  ***************** */
const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUiKey, (state, action) => {
      state[action.payload[key]] = action.payload.data;
    })
    .addCase(toggleUiModal, (state, action) => {
      state.modals[action.key] = action.value;
    })
    .addCase(toggleLoader, (state, action) => {
      state.loaders[action.keys] = action.value;
    })
    .addCase(setUILanguage, (state, action) => {
      state.language[action.key] = action.value;
    })
    .addCase(setUILanguageWithDefault, (state, action) => {
      state.defaultLanguage = action.value;
      Object.keys(state.language).forEach((item) => {
        return (state.language[item] = action.value);
      });
    });
});

export default uiReducer;
