import React from "react";
import { Navigate } from "react-router-dom";

import { PATHS } from "configs/routeConfig";
import { useAuthHook } from "hooks/auth/useAuthHook";

/**
 * Private Route
 */
export default function PrivateRoute({ children }) {
  const { access_token } = useAuthHook();

  if (!access_token) {
    return <Navigate to={PATHS.DEFAULT} />;
  }

  return children;
}
