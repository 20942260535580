import {
  ModulePermissionGate,
  PERMISSION_SCOPES,
  formatUrlPathScopes,
} from "components/layout/AccessControl";
import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import logo from "assets/images/logo.svg";
import shortLogo from "assets/images/short-logo.svg";
import apiConfig from "configs/apiConfig";
import { MenuListCollapsed } from "configs/menuList";
import { PATHS } from "configs/routeConfig";
import { useProfileHook } from "hooks/profile/useProfileHook";
import { usePermissionsHook } from "hooks/role/usePermissionsHook";
import { setDefaultProfilePic } from "utils/functions";

import { generateAsideMenu } from "./generateAsideMenu";
import { ThemeToggle } from "./ThemeToggle";

import "react-perfect-scrollbar/dist/css/styles.css";

function AsideMenu({ showBlur, handleSidebarClose }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const { pathWithoutScope } = formatUrlPathScopes(pathname, params);

  const { userData, refetchUserData, editProfile } = useProfileHook();
  const { setModulePermissions } = usePermissionsHook();
  const asideMenuLoading = useSelector(
    (state) => state.permissions.isModuleLoading
  );

  useEffect(() => {
    (async () => {
      if (userData) {
        await refetchUserData();
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (userData?.data?.roles[0]?.id) {
        setModulePermissions({
          role: userData?.data?.roles[0],
          admin: userData?.data?.permissions,
        });
      }
    })();
  }, [userData]);

  /** *****************   
  @purpose : Used For handle Profile Path
  @Parameter : {}
  @Author : INIC
  ***************** */
  const handleProfilePath = () => {
    navigate(PATHS.MY_PROFILE);
  };

  /** *****************   
  @purpose : Used For handle update Password Path
  @Parameter : {}
  @Author : INIC
  ***************** */
  const handleUpdatePasswordPath = () => {
    navigate(PATHS.CHANGE_PASSWORD);
  };

  /** *****************   
  @purpose : Used For handle update theme
  @Parameter : {}
  @Author : INIC
  ***************** */
  const handleThemeUpdate = (isThemeDark) => {
    editProfile({ isThemeDark });
  };

  const redirectToDashboard = () => navigate(PATHS.DEFAULT);

  return (
    <>
      {/* <PerfectScrollbar> */}
      <aside className="global-sidebar">
        {/* Floating Sidebar for desktop */}

        <PerfectScrollbar className={showBlur ? "show-blur" : ""}>
          <div className="sidebar-floating">
            {/* Logo */}
            <div className="logo-wrapper">
              <a
                className="d-inline-block"
                role="button"
                onClick={redirectToDashboard}
              >
                <Image
                  src={logo}
                  alt="Pointili-logo"
                  className="img-fluid"
                  width={145}
                  height={56}
                  title="Pointili"
                />
              </a>
            </div>

            <div className="sidebar" id="sidebarFloting">
              {/* Navigation Menu */}
              {asideMenuLoading ? <></> : generateAsideMenu(pathname)}
            </div>

            <div className="global-sidebar-footer">
              {/* <!-- START : Theme Toggle Switch --> */}
              <ThemeToggle
                handleThemeUpdate={handleThemeUpdate}
                isThemeDark={userData?.data?.isThemeDark || false}
              />
              {/* <!-- END : Theme Toggle Switch --> */}

              <div className="logged-user-card">
                <div className="logged-user-card-info">
                  <figure className="avatar-40 radius-circle">
                    <Image
                      src={apiConfig.FIlE_UPLOAD + userData?.data?.photo}
                      alt={`${userData?.data?.firstName || ""} ${
                        userData?.data?.lastName || ""
                      }`}
                      onError={setDefaultProfilePic}
                    />
                  </figure>
                  <div className="flex-top flex-column">
                    <p className="logged-user-card-name">
                      {userData?.data?.firstName} {userData?.data?.lastName}
                    </p>
                    <span className="logged-user-card-mail">
                      {userData?.data?.emailId}
                    </span>
                  </div>
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="ghost-primary"
                    size="sm"
                    className="btn-only-icon radius-circle no-caret"
                  >
                    <span className="inic inic-more-1"></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleProfilePath()}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleUpdatePasswordPath()}>
                      Update Password
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* <!-- END : Logged User Card --> */}
          </div>
        </PerfectScrollbar>

        <div className="sidebar-fixed">
          <div className="sidebar flex-between-middle flex-column h-100">
            <ul className="list-unstyled components main-menu px-0">
              <Nav.Item as="li">
                <Nav.Link className="sidebar-link px-2 logo-wrapper">
                  <Image
                    src={shortLogo}
                    alt="Pointili-small-logo"
                    width={45}
                    height={45}
                    title="Pointili"
                  />
                </Nav.Link>
              </Nav.Item>
              {MenuListCollapsed?.map((menu) => (
                <ModulePermissionGate
                  scope={PERMISSION_SCOPES[menu.role]}
                  key={menu.title}
                >
                  <Nav.Item as="li">
                    <Nav.Link
                      className={`sidebar-link${
                        pathWithoutScope.includes(menu.href) ? " active" : ""
                      }`}
                    >
                      <span className={`${menu.icon} m-0`}></span>
                    </Nav.Link>
                  </Nav.Item>
                </ModulePermissionGate>
              ))}
            </ul>
            <ul className="list-unstyled components main-menu px-0 py-12 mt-auto border-0">
              <li className="flex-center-middle">
                <figure className="avatar-32 radius-circle">
                  <Image
                    src={apiConfig.FIlE_UPLOAD + userData?.data?.photo}
                    alt={`${userData?.data?.firstname || ""} ${
                      userData?.data?.lastname || ""
                    }`}
                    onError={setDefaultProfilePic}
                  />
                </figure>
              </li>
            </ul>
          </div>
        </div>

        {/*  Backdrop */}
        <div className="aside-backdrop" onClick={handleSidebarClose}></div>
      </aside>
      {/* </PerfectScrollbar> */}
    </>
  );
}

export default AsideMenu;
