import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const subscriptionApi = createApi({
  reducerPath: REDUCER_PATHS.SUBSCRIPTION,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getSubscriptionPermission: builder.query({
      query: () => ({
        url: apiConfig.GET_ALL_SUBSCRIPTION_PERMISSSION,
        method: "GET",
      }),
    }),
    getAllsubscriptionList: builder.query({
      query: () => ({
        url: apiConfig.GET_NORMAL_SUBSCRIPTION_LIST,
        method: "GET",
      }),
    }),
    getAllDemosubscriptionList: builder.query({
      query: () => ({
        url: apiConfig.GET_DEMO_SUBSCRIPTION_LIST,
        method: "GET",
      }),
    }),
    getClientsubscriptionList: builder.query({
      query: () => ({
        url: `${apiConfig.GET_CLIENT_DASHBOARD_SUBSCRIPTION_LIST}?day=${30}`,
        method: "GET",
      }),
    }),
    addSubscription: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_VIEW_DELETE_SUBSCRIPTION_LIST,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["subscriptionDetails", "subscription"],
    }),
    editSubscription: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ADD_VIEW_DELETE_SUBSCRIPTION_LIST}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["subscriptionDetails", "subscription"],
    }),
    subscriptionListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.SUBSCRIPTION_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&searchText=${data?.searchText}`,
        method: "GET",
      }),
      providesTags: ["subscription"],
    }),
    deleteSubscription: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_VIEW_DELETE_SUBSCRIPTION_LIST,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["subscription"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    subscriptionDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.ADD_VIEW_DELETE_SUBSCRIPTION_LIST}?subscriptionId=${data.subscriptionId}`,
        method: "GET",
      }),
      providesTags: ["subscriptionDetails"],
    }),
    subscriptionDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.SUBSCRIPTION_DOWNLOAD}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&searchText=${data?.searchText}&filters=${data.filters}`,
        method: "GET",
      }),
    }),
    activeDemoSubscription: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ACTIVE_SUBSCRIPTION_DEMO}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["activeSubscriptionDemo", "subscription"],
    }),
  }),
});

export const {
  useGetSubscriptionPermissionQuery,
  useGetAllsubscriptionListQuery,
  useGetAllDemosubscriptionListQuery,
  useGetClientsubscriptionListQuery,
  useAddSubscriptionMutation,
  useEditSubscriptionMutation,
  useLazySubscriptionListingQuery,
  useDeleteSubscriptionMutation,
  useLazySubscriptionDetailsQuery,
  useSubscriptionDetailsDownloadMutation,
  useActiveDemoSubscriptionMutation,
} = subscriptionApi;

export default subscriptionApi;
