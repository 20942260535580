import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const profileApi = createApi({
  reducerPath: REDUCER_PATHS.PROFILE,
  baseQuery: customFetchBase,
  tagTypes: ["Profile", "notifications"],
  endpoints: (builder) => ({
    profile: builder.query({
      query: () => ({
        url: apiConfig.PROFILE,
        method: "GET",
      }),
      providesTags: ["Profile"],
      transformResponse: (resp) => transformResponseHandler(resp, false),
    }),
    editProfile: builder.mutation({
      query: (data) => ({
        url: apiConfig.EDIT_PROFILE,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Profile"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: apiConfig.CHANGE_PASSWORD,
        method: "POST",
        body: data,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    fileUpload: builder.mutation({
      query: (data) => ({
        url: apiConfig.FILE_UPLOAD,
        method: "POST",
        body: data,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    globalConfig: builder.query({
      query: () => ({
        url: apiConfig.GLOBAL_CONFIG,
        method: "GET",
      }),
      providesTags: ["Profile"],
    }),
    deleteProfile: builder.mutation({
      query: (data) => ({
        url: apiConfig.DELETE_PROFILE,
        method: "DELETE",
        body: data,
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    notifications: builder.query({
      query: () => ({
        url: apiConfig.ADMIN_NOTIFICATIONS,
        method: "GET",
      }),
      providesTags: ["notifications"],
      transformResponse: (response) =>
        transformResponseHandler(response, false),
    }),
    deleteUserNotifications: builder.mutation({
      query: (data) => ({
        url: apiConfig.NOTIFICATIONS,
        method: "delete",
        headers: {
          role: "admin",
        },
        body: data,
      }),
      invalidatesTags: ["notifications"],
      transformResponse: (response) => transformResponseHandler(response),
    }),
  }),
});

export const {
  useProfileQuery,
  useLazyNotificationsQuery,
  useDeleteUserNotificationsMutation,
  useEditProfileMutation,
  useChangePasswordMutation,
  useFileUploadMutation,
  useGlobalConfigQuery,
  useDeleteProfileMutation,
} = profileApi;

export default profileApi;
