import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";

import apiConfig from "configs/apiConfig";
import { perimssionsActions } from "store/actions/permissions";
import {
  loginUserTokenAction,
  logoutUserTokenAction,
} from "store/actions/token";
import { messageNotification, rememberMe } from "utils/functions";

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: apiConfig.API_URL,
  // Prepare headers
  prepareHeaders: (headers, { getState }) => {
    const tokenState = getState().token;
    const token = tokenState.access_token;
    const refreshToken = tokenState.refreshToken;
    if (token) {
      headers.set("authorization", token);
      headers.set("deviceid", "web");
      headers.set("refreshToken", refreshToken);
      headers.set("Technology", "react");
    }
    return headers;
  },
});

export const customFetchBase = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (
    result?.error?.status === 401 &&
    result?.error?.data?.message === "Token expired."
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          { method: "GET", url: apiConfig.REFRESH_TOKEN },
          api,
          extraOptions
        );
        if (refreshResult?.data?.status) {
          api.dispatch(loginUserTokenAction(refreshResult?.data));
          // Try again initial query
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
      messageNotification(result?.error?.data?.message, "error");
    }
  } else if (
    result?.error?.status === 400 &&
    result?.error?.data?.message === "Invalid token."
  ) {
    // RemoveMutationData();
    await api.dispatch(logoutUserTokenAction());
    await api.dispatch(
      perimssionsActions({
        modules: [],
        formatedModulesData: {},
        slug: "",
        id: "",
        role: "",
        isModuleLoading: true,
      })
    );
    rememberMe();
    const htmlEle = document.getElementsByTagName("html")[0];
    htmlEle.setAttribute("data-theme", "light");
  } else {
    messageNotification(result?.error?.data?.message, "error");
  }
  return result;
};

export default class BaseApi {
  constructor({ reducerPath }) {
    this.reducerPath = reducerPath;
  }

  createApi = () =>
    createApi({
      reducerPath: this.reducerPath,
      baseQuery: customFetchBase,
      endpoints: () => ({}),
    });
}
