import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const demoApi = createApi({
  reducerPath: REDUCER_PATHS.DEMO,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    //Demo User
    demoUsersList: builder.mutation({
      query: (data) => ({
        url: apiConfig.GET_DEMO_USERS,
        body: data,
        method: "POST",
      }),
      providesTags: ["demoUsers"],
    }),
    //Demo User
    demoUsersDownlodsList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DOWNLOADS_DEMO_USERS}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
      providesTags: ["demoUsers"],
    }),

    // DELETE DEMO USER
    deleteDemoUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.DELETE_DEMO_USER,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["demoUsers"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    demoRequestList: builder.mutation({
      query: (data) => ({
        url: apiConfig.GET_DEMO_REQUEST,
        body: data,
        method: "POST",
      }),
      providesTags: ["demoRequest"],
    }),
    demoRequestListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["demoRequest", "demoRequestListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    demoRequestListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["demoRequest"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addDemoRequestList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_DEMO_REQUEST,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["demoRequest"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editDemoRequestList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DEMO_REQUEST_UPDATE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["demoRequest"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    demoRequestDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.DEMO_REQUEST_DETAILS}/${data.id}`,
        method: "GET",
      }),
      providesTags: ["demoRequestDetails", "demoRequest"],
    }),
    demoRequestDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DEMO_REQUEST_DOWNLOAD}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),

    //Booked Meeting

    bookedMeetingList: builder.mutation({
      query: (data) => ({
        url: apiConfig.GET_BOOKED_MEETING,
        body: data,
        method: "POST",
      }),
      providesTags: ["BookedMeeting"],
    }),
    bookedMeetingListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.REFERRAL_SOURCE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["BookedMeeting", "bookedMeetingListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    bookedMeetingListDelete: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_DELETE}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BookedMeeting", "bookedMeetingListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addBookedMeetingList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_BOOKED_MEETING,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["bookedMeetingDetails", "bookedMeetingListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editBookedMeetingList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_UPDATE}`,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["bookedMeetingDetails", "bookedMeetingListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    bookedMeetingDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_DETAILS}/${data.id}`,
        method: "GET",
      }),
      providesTags: ["bookedMeetingDetails"],
    }),
    bookedMeetingDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_DOWNLOAD}?page=${data?.page}&pageSize=${data?.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),

    //Commercial User
    getCommercialUser: builder.query({
      query: () => ({
        url: apiConfig.GET_COMMERCIAL_USER,
        method: "GET",
      }),
      providesTags: ["CommercialUser"],
    }),

    getParticipantsList: builder.query({
      query: () => ({
        url: `${apiConfig.GET_PARTICIPANTS_USER}`,
        method: "GET",
      }),
    }),

    // Demo Configuration

    getDemoConfigurationDetails: builder.query({
      query: () => ({
        url: `${apiConfig.GET_DEMO_CONFIGURATION_DETAILS}`,
        method: "GET",
      }),
      providesTags: ["demoConfiguration"],
    }),

    reInitializedemoConfigurationDetails: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.RE_INITIALIZE_DEMO_REQUEST}`,
        body: data,
        method: "PUT",
      }),
      providesTags: ["reInitializedemoConfiguration"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    reInitializerequestConfigurationDetails: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.RE_INITIALIZE_MEETING_REQUEST}`,
        body: data,
        method: "PUT",
      }),
      providesTags: ["reInitializemettingConfiguration"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    editdemoConfiguration: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_DEMO_MEETING_CONFIGURATION}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["demoConfiguration"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    //Expire Demos
    expireDemosList: builder.mutation({
      query: (data) => ({
        url: apiConfig.GET_EXPIRE_DEMO,
        body: data,
        method: "POST",
      }),
      providesTags: ["expireDemos"],
    }),

    editExpireDemosList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_UPDATE}/${data.id}`,
        body: data.postData,
        method: "PUT",
      }),
      invalidatesTags: ["expireDemosDetails", "expireDemosListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    expireDemosDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.BOOKED_MEETING_DETAILS}/${data.id}`,
        method: "GET",
      }),
      providesTags: ["expireDemosDetails"],
    }),
  }),
});

export const {
  useDemoRequestListMutation,
  //useGetDemoRequestQuery,
  // useDemoRequestListMutation,
  useDemoRequestListChangeStatusMutation,
  useDemoRequestListDeleteMutation,
  useAddDemoRequestListMutation,
  useEditDemoRequestListMutation,
  useLazyDemoRequestDetailsQuery,
  useDemoRequestDownloadMutation,

  // BookedMeeting
  useBookedMeetingListMutation,
  //useGetDemoRequestQuery,
  // useDemoRequestListMutation,
  useBookedMeetingListChangeStatusMutation,
  useBookedMeetingListDeleteMutation,
  useAddBookedMeetingListMutation,
  useEditBookedMeetingListMutation,
  useLazyBookedMeetingDetailsQuery,
  useBookedMeetingDownloadMutation,

  //Commercial User
  useGetCommercialUserQuery,
  useGetParticipantsListQuery,

  //Configuration Demo
  useGetDemoConfigurationDetailsQuery,
  useReInitializedemoConfigurationDetailsMutation,
  useReInitializerequestConfigurationDetailsMutation,
  useEditdemoConfigurationMutation,

  //Expire Demo
  useExpireDemosListMutation,
  useEditExpireDemosListMutation,
  useLazyExpireDemosDetailsQuery,

  //demo Users
  useDemoUsersListMutation,
  useDemoUsersDownlodsListMutation,
  useDeleteDemoUserMutation
} = demoApi;

export default demoApi;
