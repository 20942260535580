import configs from "configs";

export default {
  API_URL: configs.REACT_APP_SERVER_URL,
  FIlE_UPLOAD: `${configs.FILE_IMAGE_URL}`,
  IMAGE_DOWNLOAD: `${configs.FILE_IMAGE_URL}`,
  FILE_DOWNLOAD: `${configs.FILE_DOWNLOAD_URL}`,
  GOOGLE_MAPS_API_KEY: `${configs.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  ENCRYPTION_KEY: `${configs.REACT_APP_ENCRYPTION_KEY}`,

  // CMS Api url
  PRIVACY_POLICY: "PRIVACY_POLICY",

  // Posts api url
  POSTS: "posts",

  // Auth api url
  LOGIN: "admin/login",

  SEND_OTP: "admin/send-otp",

  VERIFY_OTP: "admin/verify-otp",
  LOGOUT: "admin/logout",

  REFRESH_TOKEN: "admin/refresh-access-token",

  FORGOT_PW: "admin/forgot-password",

  RESET_PW: "admin/reset-password",

  GET_COLUMN_TABLE_SETTINGS: "admin/table-column-settings",

  // User profile url
  PROFILE: "admin/profile",
  EDIT_PROFILE: "admin/edit-profile",
  FILE_UPLOAD: "admin/image-file-upload",
  CHANGE_PASSWORD: "admin/change-password",
  GLOBAL_CONFIG: "admin/global-config",
  DELETE_PROFILE: "admin",
  ADMIN_NOTIFICATIONS: "admin/notifications",
  NOTIFICATIONS: "notifications",

  // Master => Timezone
  TIMEZONE: "master/timezone",
  TIMEZONE_LIST: "master/timezone/listing",
  // Master => Country
  COUNTRY: "master/country",
  COUNTRY_LIST: "master/country/listing",
  CURRENCY_LIST: "master/currency",
  GET_CURRENCY_LIST: "/master/currency/listing",
  // Master => Language
  LANGUAGE: "master/language",
  GET_LANGUAGE: "master/language/listing",

  // Master => Language
  INDUSTRY: "master/industry",
  GET_INDUSTRY: "master/industry/listing",
  INDUSTRY_DETAILS: "master/industry/details",
  INDUSTRY_DETAILS_DOWNLOADS: "master/industry/download",

  // Master => Goals
  ORGANIZATION_GOAL: "master/goal",
  GET_ORGANIZATION_GOAL: "master/goal/listing",
  ORGANIZATION_GOAL_DETAILS: "master/goal/details",

  // Master => Devices
  ORGANIZATION_DEVICE: "master/device",
  GET_ORGANIZATION_DEVICE: "master/device/listing",
  ORGANIZATION_DEVICE_DETAILS: "master/device/details",

  // Master => Organization Size
  ORGANIZATION_SIZE: "master/organization-size",
  GET_ORGANIZATION_SIZE: "master/organization-size/listing",
  ORGANIZATION_DETAILS_DOWNLOADS: "organization-size/download",

  // Master => Domain Of Work
  DOMAIN_OF_WORK: "master/domainOfWork",
  GET_DOMAIN_OF_WORK: "master/domainOfWork/listing",
  DOMAIN_OF_WORK_DETAILS: "master/domainOfWork/details",
  DOMAIN_OF_WORK_DETAILS_DOWNLOADS: "master/domainOfWork/download",

  // Master => referral source
  REFERRAL_SOURCE: "master/referral-source",
  UPDATE_REFERRAL_SOURCE: "master/referralSource",
  GET_REFERRAL_SOURCE: "master/referral-source/listing",
  REFERRAL_SOURCE_DETAILS: "master/referral-source/details",
  REFERRAL_SOURCE_DETAILS_DOWNLOADS: "master/referral-source/download",

  // Master => contract type
  CONTRACT_TYPE: "master/contract-types",
  GET_CONTRACT_TYPE: "master/contract-types/listing",
  CONTRACT_TYPE_DETAILS: "master/contract-type/details",

  // Master => civil status
  CIVIL_STATUS: "master/civil-status",
  //GET_CIVIL_STATUS: "civil-status/listing",
  CIVIL_STATUS_DETAILS: "master/civil-status/details",

  // Master => work arrangement
  WORK_ARRANGEMENT: "master/work-arrangement",
  UPDATE_WORK_ARRANGEMENT: "master/work-arrangement",
  GET_WORK_ARRANGEMENT: "master/work-arrangement/listing",
  WORK_ARRANGEMENT_DETAILS: "master/work-arrangement/details",

  // Master => Job Position
  JOB_POSITION: "master/job-positions",
  UPDATE_JOB_POSITION: "master/job-positions",
  GET_JOB_POSITION: "job-positions/listing",
  JOB_POSITION_DETAILS: "master/job-position/details",

  // Master => Employee Type
  EMPLOYEE_TYPE: "master/employee-types",
  UPDATE_EMPLOYEE_TYPE: "master/employee-types",
  GET_EMPLOYEE_TYPE: "employee-type/listing",
  EMPLOYEE_TYPE_DETAILS: "master/employee-type/details",

  //  settings
  META_DATA: "settings/general/meta-data",
  SITE_SETTING: "settings/general/site-settings",
  DATE_TIME: "settings/general/date-time",
  ANALYTICAL_DATA: "settings/general/analytical-data",
  SITE_UN_MAINTENANCE: "settings/general/site-un-maintenance",
  SOCIAL_MEDIA_SDK: "settings/social-media/social-media-sdk",
  SOCIAL_MEDIA_LINK: "settings/social-media/social-media-link",
  CHANGE_STATUS: "settings/social-media/social-media-sdk",
  SMTP_DETAILS: "settings/smtp",
  SMS_DETAILS: "settings/sms",
  NOTIFICATION: "settings/email-settings/notifications",
  LANGUAGE_MESSAGES: "settings/language-preferences/messages",
  LANGUAGE_LABEL: "settings/language-preferences/label",
  SYNC_TRANSLATIONS: "labels/sync",
  ADD_EDIT_PAYMENT: "settings/payment-gateway",
  TWO_WAY_AUTHENTICATION: "settings/general/change-2fa-status",
  GET_TWO_WAY_AUTHENTICATION: "settings/general/get-2fa",
  //role
  GET_ALL_PERMISSSION: "role/get-all-permission",
  ROLE: "role",
  CHANGE_ROLE: "/role/change-role",
  ROLE_LISTING: "role/listing",
  USER_LIST: "role/user-list",
  ROLES_DOWNLOAD: "/role/download",

  //subscription
  GET_ALL_SUBSCRIPTION_PERMISSSION: "subscription-plan/permissions",
  ADD_VIEW_DELETE_SUBSCRIPTION_LIST: "subscription-plan",
  SUBSCRIPTION_LISTING: "subscription-plan/listing",
  SUBSCRIPTION_DOWNLOAD: "subscription-plan/download",
  GET_NORMAL_SUBSCRIPTION_LIST: "subscription-plan/normal-listing",
  GET_DEMO_SUBSCRIPTION_LIST: "subscription-plan/demo-listing",
  GET_CLIENT_DASHBOARD_SUBSCRIPTION_LIST: "client/subscription/expired-soon",
  ACTIVE_SUBSCRIPTION_DEMO: "subscription-plan/activate-demo",

  //emailTemplates
  LISTING: "email-template/listing",
  STATUS_CHANGE: "email-template/change-status",
  EMAIL_TEMPLATES: "email-template",

  //blog
  BLOG_CATEGORY_LISTING: "blog-categories/listing",
  BLOG_CATEGORY: "blog-categories",
  BLOG_CATEGORY_LIST: "blog-categories/dropdown",
  BLOG_LIST: "blog/listing",
  BLOG: "blog",
  BLOG_LIST_DOWNLOAD: "blog/download",
  BLOG_PUBLISH: "blog/publish",
  BLOG_CATEGORY_DOWNLOAD: "blog-categories/download",

  //faq
  FAQ_CATEGORY_LISTING: "faq-categories/listing",
  FAQ_CATEGORY: "faq-categories",
  FAQ_CATEGORY_LIST: "faq-categories/dropdown",
  FAQ_LISTING: "faq/listing",
  FAQ: "faq",

  //users
  GET_ADMIN_MANAGEMENT_LIST: "client/contact/admin",
  LISTING_ADMIN_MANAGEMENT: "admin-management",
  ADMIN_MANAGEMENT: "admin-management",
  LISTING_USER_MANAGEMENT: "user-management/listing",
  ADMIN_USERS_DOWNLOAD: "admin-management/download",
  USER_MANAGEMENT_DOWNLOAD: "user-management/download",
  USER_MANAGEMENT: "user-management",
  USER_DETAILS_DOWNLOAD: "user-management/download",

  //Static page management
  STATIC_PAGE_MANAGEMENT_LISTING: "static-page/listing",
  STATIC_PAGE_MANAGEMENT: "static-page",
  STATIC_PAGE_MANAGEMENT_DOWNLOAD: "staticPage/download",

  //Commom Filter API
  SAVE_FILTER: "common/filter",
  SAVE_COLUMN: "common/column",

  //Support
  SUPPORT: "support",
  SUPPORT_REPLY: "support/reply",

  // Home Page Cms => Partners
  PARTNERS: "cms/partners",
  GET_PARTNERS: "cms/partners/listing",
  PARTNERS_DETAILS: "cms/partner/details",

  // Home Page Cms => Benefits
  BENEFITS: "cms/benefits",
  GET_BENEFITS: "cms/benefits/listing",
  BENEFITS_DETAILS: "cms/benefit/details",

  // Home Page Cms => How We Work
  HOW_WE_WORK: "cms/how-we-work",
  GET_HOW_WE_WORK: "cms/how-we-work/listing",
  HOW_WE_WORK_DETAILS: "cms/how-we-work/details",

  // Home Page Cms => Testimonials
  TESTIMONIALS: "cms/testimonials",
  GET_TESTIMONIALS: "cms/testimonials/listing",
  TESTIMONIALS_DETAILS: "cms/testimonial/details",

  // Home Page Cms => Capabilities
  CAPABILITIES: "/cms/capabilities",
  GET_CAPABILITIES: "/cms/capabilities/listing",
  CAPABILITIES_DETAILS: "/cms/capability/details",

  // Main Banner => Web Main Banner
  WEB_MAIN_BANNER: "/cms/web-banner",

  // Main Banner => Mobile Main Banner
  MOBILE_MAIN_BANNER: "/cms/mobile-banner",
  GET_WEB_MAIN_BANNER: "/get-banners",

  // Demos => Demo Request
  GET_DEMO_REQUEST: "demo-request/listing",
  ADD_DEMO_REQUEST: "demo-request/add",
  DEMO_REQUEST_DETAILS: "demo-request/get-demo-details",
  DEMO_REQUEST_UPDATE: "demo-request/update-demo-request",
  DEMO_REQUEST_DOWNLOAD: "demo-request/download",

  // Demos => Booked Meeting
  GET_COMMERCIAL_USER: "demo-meeting/getPortalUsers",
  GET_BOOKED_MEETING: "demo-meeting/listing",
  ADD_BOOKED_MEETING: "demo-meeting/add",
  BOOKED_MEETING_DETAILS: "demo-meeting/get-demo-details",
  BOOKED_MEETING_UPDATE: "demo-meeting/update-demo-meeting",
  BOOKED_MEETING_DOWNLOAD: "demo-meeting/download",
  BOOKED_MEETING_DELETE: "demo-meeting/delete",
  GET_PARTICIPANTS_USER: "client/contact/participant",

  // Demos => Configulation
  UPDATE_DEMO_MEETING_CONFIGURATION: "configuration/update-demo-configuration",
  GET_DEMO_CONFIGURATION_DETAILS: "/configuration/get-demo-configuration",
  RE_INITIALIZE_DEMO_REQUEST: "configuration/re-initialize-number/demoRequest",
  RE_INITIALIZE_MEETING_REQUEST:
    "configuration/re-initialize-number/meetingRequest",

  //expire Demo
  GET_EXPIRE_DEMO: "demo-request/get-demo-users",

  //demo users
  GET_DEMO_USERS: "demo-request/get-demo-users",
  DOWNLOADS_DEMO_USERS: "demo-request/download-demo-users",
  DELETE_DEMO_USER:"demo-user/delete",

  // Users => Client
  GET_CLIENT_DROPDOWN: "client/contact/getOrganizations",
  GET_CLIENT_REQUEST: "client/listing",
  ADD_CLIENT_REQUEST: "client/add",
  CLIENT_STATUS_CHANGE: "client",
  CLIENT_REQUEST_DELETE: "client",
  CLIENT_REQUEST_UPDATE: "client/update",
  CHANGE_CLIENT_PW: "client/changePassword",
  IMPORT_CLIENT_LIST: "client/importClientContacts",
  HISTORY_CLIENT_LIST: "client/getHistory",
  DOWNLOADS_CSV_CLIENT_LIST: "client/common-csv-sample",
  VERIFY_PAYMENT: "client/verify-payment",
  UPDATE_SUBSCRIPTION: "/client/subscription/update",

  // Users => Contact
  GET_CONTACT_USERS: "client/contactListing",
  ADD_CONTACT_USERS: "client/addContacts",
  CONTACT_USERS_DELETE: "client/contacts",
  CONTACT_USERS_UPDATE: "/client/updateContact",
  CONTACT_USERS_DOWNLOADS: "client/downloadClientContacts",
  IMPORT_CONTACT_LIST: "client/importClientContacts",
  MERGE_CONTACT_LIST: "client/mergeContacts",
  DOWNLOADS_CSV_CONTACT_LIST: "client/contact/common-csv-sample",

  //comment
  ADD_COMMENT_USERS: "client/addComment",
  COMMENT_USERS_UPDATE: "client/getComments",

  // users => Configulation
  UPDATE_PORTFOLIO_CONFIGURATION: "client/updateConfiguration",
  GET_PORTFOLIO_DETAILS: "client/getConfiguration",

  // CLIENT_REQUEST_DOWNLOAD: "demo-request/download",

  // Users => Client Dashboard
  GET_CLIENT_DASHBOARD: "client/getClientsDashboard",
  DOWNLOADS_CLIENT_DASHBOARD: "client/download",
};
