import moment from "moment";
import React from "react";
import Image from "react-bootstrap/Image";

import profilepic from "assets/images/profile-pic.jpg";
import { formatNotificationType } from "utils/functions";

function NotificationBox({ notificationList, onClear }) {
  return (
    <>
      <div className="dropdown-menu ctm-dropdown dropdown-lg shadow onhover-show-div show">
        <div className="dropdown-header flex-between-baseline">
          <span className="dropdown-title">Notification</span>
          <a
            href="javascript:;"
            className="link-primary anchor-hover p-0"
            onClick={onClear}
          >
            Clear all
          </a>
        </div>
        <ul className="list-unstyled dropdown-list">
          {notificationList?.length > 0 &&
            notificationList?.map((notification, index) => (
              <li key={`notification-${index}`}>
                <div className="notification-item flex-between-start">
                  <figure className="avatar-32 rounded-circle">
                    <Image src={profilepic} alt="profile-pic" />
                  </figure>
                  <div className="messages ">
                    <span className="username">
                      <a href="#" className="fw-500  p-0 pb-4 text-black">
                        {formatNotificationType(notification?.message?.type)}
                      </a>
                      <a className="close fw-semibold p-0" href="javascript:;">
                        <span className="icn-close"></span>
                      </a>
                    </span>
                    <div className="messages-content-time text-dark-60">
                      <span className="line-clamp-2 w-75 msg-content">
                        {formatNotificationType(notification?.message?.text)}
                      </span>
                      <span className="msg-time">
                        {moment(notification?.message?.currentTime).fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default NotificationBox;
