export const REDUCER_PATHS = {
  AUTH: "auth",
  TOKEN: "token",
  USERS: "users",
  UI: "ui",
  MASTER: "master",
  CMS: "cms",
  PROFILE: "profile",
  SETTINGS: "settings",
  ROLE: "role",
  EMAIL_TEMPLATES: "emailTemplates",
  BLOG: "blog",
  FILTER: "filter",
  SUPPORT: "support",
  DEMO: "demo",
  SUBSCRIPTION: "subscription",
};

export const REDUCER_TAGS = {
  POSTS: "Posts",
  PROFILE: "Profile",
  SETTINGS: "settings",
  USERS: "users",
};
