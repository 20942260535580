export const fixedCacheKeys = {
  LOGIN: "user-login-post",
  SEND_OTP: "user-otp-post",
  VERIFY_OTP: "user-verify-post",
  LOGOUT: "user-verify-get",
  FORGOT_PASSWORD: "user-forgotPassword-post",
  RESET_PASSWORD: "user-resetPassword-post",
  EDIT_PROFILE: "user-profileData-Post",
  FILE_UPLOAD: "user-File-post",
  CHANGE_PASSWORD: "user-changePassword-post",
  DELETE_PROFILE: "user-deleteProfile-delete",
  ADD_META_DATA: "user-addMetaData-post",
  EDIT_META_DATA: "user-editMetaDta-put",
  EDIT_SITE_SETTING_DATA: "user-editSiteSettingData-put",
  EDIT_ANALYTICAL_DATA: "user-editAnalyticalData-put",
  ADD_SITE_DATA: "user-addSiteData-Post",
  ADD_ANALYTICAL_DATA: "user-addAnalyticalData-post",
  ADD_MAINTENANCE_DATA: "user-editMaintenanceData-post",
  EDIT_MAINTENANCE_DATA: "user-editMaintenanceData-put",
  DATE_TIME: "user-dateTime-Post",
  EDIT_DATE_TIME: "user-dateTime-put",
  ADD_SOCIAL_MEDIA_SDK: "user-addSocialMediaSdk-post",
  EDIT_SOCIAL_MEDIA_SDK: "user-editSocialMediaSdk-put",
  ADD_SOCIAL_MEDIA_LINK: "user-addSocialMediaLink-post",
  EDIT_SOCIAL_MEDIA_LINK: "user-editSocialMediaLink-put",
  CHANGE_STATUS: "user-changeStatus-post",
  ADD_SMTP_DETAILS: "user-addSmtpDetails-post",
  EDIT_SMTP_DETAILS: "user-editSmtpDetails-put",
  ADD_SMS_DETAILS: "user-addSmsDetails-post",
  EDIT_SMS_DETAILS: "user-editSmsDetails-put",
  NOTIFICATION_STATUS: "setting-notificationStatus-post",
  ADD_PAYMENT_DETAILS: "setting-paymentData-post",
  EDIT_PAYMENT_DETAILS: "setting-paymentData-put",
  TIMEZONE_LIST: "country-timezoneList-post",
  TIMEZONE_DELETE: "country-timeZoneData-delete",
  ADD_TIMEZONE: "country-addTimezone-post",
  TIMEZONE_CHANGE_STATUS: "timezone-changeStatus-post",
  COUNTRY_LIST: "country-list-post",
  COUNTRY_LIST_DELETE: "country-list-delete",
  COUNTRY_LIST_CHANGE_STATUS: "country-list-changeStatus-patch",
  ADD_COUNTRYLIST: "country-addCountryList-post",
  CURRENCY_LIST_DELETE: "currency-list-delete",
  CURRENCY_LIST_CHANGE_STATUS: "currency-list-changeStatus-patch",
  ADD_CURRENCY_LIST: "currency-addCountryList-post",
  LANGUAGE_MESSAGES: "language-messages-post",
  ADD_LANGUAGE_MESSAGES: "language-addMessages-post",
  LANGUAGE_LABEL: "language-label-post",
  ADD_LANGUAGE_LABEL: "language-addEditLabel-post",
  EDIT_LANGUAGE_LABEL: "language-editLabel-put",
  LANGUAGE_LIST: "country-language-post",
  LANGUAGE_LIST_CHANGE_STATUS: "language-list-changeStatus-patch",
  ADD_LANGUAGELIST: "country-addLanguageList-post",
  EDIT_LANGUAGELIST: "language-editLanguageList-put",
  DETAIL_LANGUAGELIST: "language-detailLanguageList-get",
  ADD_ROLE: "role-addRole-post",
  EDIT_ROLE: "role-editRole-put",
  ROLE_LIST: "role-roleList-post",
  CHANGE_ROLE: "role-changeRole-patch",
  DELETE_ROLE: "role-deleteRole-delete",
  USER_LIST: "role-usersList-get",
  ROLES_DETAILS_DOWNLOAD: "roles-adminrolesDownload-get",

  EMAIL_TEMPLATES_LISTINGS: "email-emailTemlates-post",
  EMAIL_TEMPLATES_STATUS_CHANGE: "email-emailTemplatesStatus-post",
  EMAIL_TEMPLATES_DELETE: "email-emailTemplatesDelete-delete",
  EMAIL_TEMPLATES_ADD: "email-addEmailTemplates-post",
  EMAIL_TEMPLATES_EDIT: "email-editEmailTemplates-post",
  BLOG_CATEGORY_LISTING: "blog-blogCategoryListing-post",
  BLOG_CATEGORY_STATUS: "blog-blogCategoryStatus-post",
  BLOG_CATEGORY_DELETE: "blog-blogCategoryDelete-post",
  BLOG_CATEGORY_ADD: "blog-blogCategoryAdd-post",
  BLOG_CATEGORY_EDIT: "blog-blogCategoryEdit-post",
  BLOG_CATEGORY_LIST_DOWNLOAD: "blog-blogCategoriesDownload-get",
  BLOG_LIST: "blog-blogList-post",
  BLOG_ADD_LIST: "blog-blogAddEditList-post",
  BLOG_EDIT_LIST: "blog-blogEdit-post",
  BLOG_DELETE: "blog-blogDelete-delete",
  BLOG_PUBLISH: "blog-blogPublishStatusChange-patch",
  BLOG_LIST_DOWNLOAD: "blog-blogDownload-get",

  //bookedmeeting
  BOOKED_MEETING_LIST_CHANGE_STATUS: "bookedmeeting-bookedmeetingStatus-post",
  BOOKED_MEETING_LIST_DELETE: "bookedmeeting-list-delete",
  ADD_BOOKED_MEETINGLIST: "bookedmeeting-addBookedmeetingList-post",
  EDIT_BOOKED_MEETINGLIST: "bookedmeeting-editBookedmeetingList-put",
  DETAIL_BOOKED_MEETINGLIST: "bookedmeeting-detailBookedmeetingList-get",
  BOOKED_MEETING_DOWNLOAD_LIST: "bookedmeeting-list-downloads",

  //demorequest
  DEMO_REQUEST_LIST_CHANGE_STATUS: "demorequest-demorequestStatus-post",
  DEMO_REQUEST_LIST_DELETE: "demorequest-list-delete",
  ADD_DEMO_REQUEST_LIST: "demorequest-addDemorequestList-post",
  EDIT_DEMO_REQUEST_LIST: "demorequest-editDemorequestList-put",
  DETAIL_DEMO_REQUEST_LIST: "demorequest-detailDemorequestList-get",
  DEMO_REQUEST_DOWNLOAD_LIST: "demorequest-list-downloads",
  DELETE_DEMO_USER:"demo-user/delete",

  //expireDemo
  EDIT_EXPIRE_DEMO_LIST: "expireDemo-list-post",

  EXPIRE_DEMO_DETAIL_REQUEST_LIST: "expireDemo-detailExpireDemotList-get",

  //demoConfiguration
  RE_INITIALIZE_DEMO_CONFIGURATION: "re-initialize-demo-configuration",
  RE_INITIALIZE_REQUEST_CONFIGURATION: "re-initialize-request-configuration",
  EDIT_CONFIGURATION_DETAILS: "update-configuration",

  //industry
  INDUSTRY_LIST_CHANGE_STATUS: "industry-industryStatus-post",
  INDUSTRY_LIST_DELETE: "industry-list-delete",
  ADD_INDUSTRYLIST: "industry-addIndustryList-post",
  EDIT_INDUSTRYLIST: "industry-editIndustryList-put",
  DETAIL_INDUSTRYLIST: "industry-detailIndustryList-get",
  //industry
  ORGANIZATION_SIZE_LIST_CHANGE_STATUS:
    "organizationSize-organizationSizeStatus-post",
  ORGANIZATION_SIZE_LIST_DELETE: "organizationSize-list-delete",
  ADD_ORGANIZATION_SIZE_LIST: "organizationSize-addorganizationSizeList-post",
  EDIT_ORGANIZATION_SIZE_LIST: "organizationSize-editorganizationSizeList-put",
  DETAIL_ORGANIZATION_SIZE_LIST:
    "organizationSize-detailorganizationSizeList-get",
  //domain of work
  DOMAIN_OF_WORK_LIST_CHANGE_STATUS: "domainofwork-domainofworkStatus-post",
  DOMAIN_OF_WORK_LIST_DELETE: "domainofwork-list-delete",
  ADD_DOMAIN_OF_WORK_LIST: "domainofwork-adddomainofworkList-post",
  EDIT_DOMAIN_OF_WORK_LIST: "domainofwork-editdomainofworkList-put",
  DETAIL_DOMAIN_OF_WORK_LIST: "domainofwork-detaildomainofworkList-get",
  //means of transportation
  MEANS_OF_TRANSPORTATION_LIST_CHANGE_STATUS:
    "meansoftransportation-meansoftransportationStatus-post",
  MEANS_OF_TRANSPORTATION_LIST_DELETE: "meansoftransportation-list-delete",
  ADD_MEANS_OF_TRANSPORTATION_LIST:
    "meansoftransportation-addmeansoftransportationList-post",
  EDIT_MEANS_OF_TRANSPORTATION_LIST:
    "meansoftransportation-editmeansoftransportationList-put",
  DETAIL_MEANS_OF_TRANSPORTATION_LIST:
    "meansoftransportation-detailmeansoftransportationList-get",

  //organization Goal
  ORGANIZATION_GOAL_LIST_CHANGE_STATUS:
    "organizationGoal-organizationGoalStatus-post",
  ORGANIZATION_GOAL_LIST_DELETE: "organizationGoal-list-delete",
  ADD_ORGANIZATION_GOAL_LIST: "organizationGoal-addorganizationGoalList-post",
  EDIT_ORGANIZATION_GOAL_LIST: "organizationGoal-editorganizationGoalList-put",
  DETAIL_ORGANIZATION_GOAL_LIST:
    "organizationGoal-detailorganizationGoalList-get",

  //organization device
  ORGANIZATION_DEVICE_LIST_CHANGE_STATUS:
    "organizationDevice-organizationDeviceStatus-post",
  ORGANIZATION_DEVICE_LIST_DELETE: "organizationDevice-list-delete",
  ADD_ORGANIZATION_DEVICE_LIST:
    "organizationDevice-addorganizationDeviceList-post",
  EDIT_ORGANIZATION_DEVICE_LIST:
    "organizationDevice-editorganizationDeviceList-put",
  DETAIL_ORGANIZATION_DEVICE_LIST:
    "organizationDevice-detailorganizationDeviceList-get",

  //referral source
  REFERRAL_SOURCE_LIST_CHANGE_STATUS:
    "referralSource-referralSourceStatus-post",
  REFERRAL_SOURCE_LIST_DELETE: "organizationDevice-list-delete",
  ADD_REFERRAL_SOURCE_LIST: "referralSource-addreferralSourceList-post",
  EDIT_REFERRAL_SOURCE_LIST: "referralSource-editreferralSourceList-put",
  DETAIL_REFERRAL_SOURCE_LIST: "referralSource-detailreferralSourceList-get",

  //civil status
  CIVIL_STATUS_LIST_CHANGE_STATUS: "civilstatus-civilstatusStatus-post",
  CIVIL_STATUS_LIST_DELETE: "civilstatus-list-delete",
  ADD_CIVIL_STATUS_LIST: "civilstatus-addCivilstatusList-post",
  EDIT_CIVIL_STATUS_LIST: "civilstatus-editCivilstatusList-put",
  DETAIL_CIVIL_STATUS_LIST: "civilstatus-detailCivilstatusList-get",

  //contract type
  CONTRACT_TYPE_LIST_CHANGE_STATUS: "contracttype-contracttypeStatus-post",
  CONTRACT_TYPE_LIST_DELETE: "contracttype-list-delete",
  ADD_CONTRACT_TYPE_LIST: "contracttype-addContracttypeList-post",
  EDIT_CONTRACT_TYPE_LIST: "contracttype-editContracttypeList-put",
  DETAIL_CONTRACT_TYPE_LIST: "contracttype-detailContracttypeList-get",

  //work arrangment
  WORK_ARRANGEMENT_LIST_CHANGE_STATUS:
    "workarrangment-workarrangmentStatus-post",
  WORK_ARRANGEMENT_LIST_DELETE: "workarrangment-list-delete",
  ADD_WORK_ARRANGEMENT_LIST: "workarrangment-addWorkarrangmentList-post",
  EDIT_WORK_ARRANGEMENT_LIST: "workarrangment-editWorkarrangmentList-put",
  DETAIL_WORK_ARRANGEMENT_LIST: "workarrangment-detailWorkarrangmentList-get",

  //Breaks Name
  BREAKS_NAME_LIST_CHANGE_STATUS: "BreaksName-BreaksNameStatus-post",
  BREAKS_NAME_LIST_DELETE: "BreaksName-list-delete",
  ADD_BREAKS_NAME_LIST: "BreaksName-addBreaksNameList-post",
  EDIT_BREAKS_NAME_LIST: "BreaksName-editBreaksNameList-put",
  DETAIL_BREAKS_NAME_LIST: "BreaksName-detailBreaksNameList-get",

  //Deductions
  DEDUCTIONS_LIST_CHANGE_STATUS: "Deductions-DeductionsStatus-post",
  DEDUCTIONS_LIST_DELETE: "Deductions-list-delete",
  ADD_DEDUCTIONS_LIST: "Deductions-addDeductionsList-post",
  EDIT_DEDUCTIONS_LIST: "Deductions-editDeductionsList-put",
  DETAIL_DEDUCTIONS_LIST: "Deductions-detailDeductionsList-get",

  //employeeType
  EMPLOYEE_TYPE_LIST_CHANGE_STATUS: "employeeType-employeeTypeStatus-post",
  EMPLOYEE_TYPE_LIST_DELETE: "employeeType-list-delete",
  ADD_EMPLOYEE_TYPE_LIST: "employeeType-addEmployeeTypeList-post",
  EDIT_EMPLOYEE_TYPE_LIST: "employeeType-editEmployeeTypeList-put",
  DETAIL_EMPLOYEE_TYPE_LIST: "employeeType-detailEmployeeTypeList-get",

  //jobPosition
  JOB_POSITION_LIST_CHANGE_STATUS: "jobPosition-jobPositionStatus-post",
  JOB_POSITION_LIST_DELETE: "jobPosition-list-delete",
  ADD_JOB_POSITION_LIST: "jobPosition-addJobPositionList-post",
  EDIT_JOB_POSITION_LIST: "jobPosition-editJobPositionList-put",
  DETAIL_JOB_POSITION_LIST: "jobPosition-detailJobPositionList-get",

  //faqCategory
  FAQ_CATEGORY_LISTING: "faq-faqCategoryListing-get",
  FAQ_CATEGORY_DELETE: "faq-faqCategoryDelete-delete",
  FAQ_CATEGORY_ADD: "faq-faqCategoryAdd-post",
  FAQ_CATEGORY_EDIT: "faq-faqCategoryEdit-put",
  FAQ_CATEGORY_DETAILS: "faq-faqCategoryDetails-get",
  FAQ_CATEGORY_STATUS_DETAILS: "faq-faqCategoryStatus-patch",
  //faq
  FAQ_LISTING: "faq-faqListing-get",
  FAQ_DELETE: "faq-faqDelete-delete",
  FAQ_ADD: "faq-faqAdd-post",
  FAQ_EDIT: "faq-faqEdit-put",
  FAQ_DETAILS: "faq-faqDetails-get",
  FAQ_STATUS_CHANGE: "faq-faqStatusChange-patch",
  //adminUser
  ADMIN_USER_DATA: "users-adminUsersData-post",
  ADD_ADMIN_USER_DATA: "users-addAdminUsersData-post",
  EDIT_ADMIN_USER_DATA: "users-editAdminUsersData-put",
  DELETE_ADMIN_USER_DATA: "users-deleteAdminUsersData-delete",
  ADMIN_USER_DETAILS_DOWNLOAD: "users-adminusersDownload-get",

  //User data
  USER_DATA: "users-UsersData-get",
  ADD_USER_DATA: "users-addUsersData-post",
  EDIT_USER_DATA: "users-editusersData-put",
  DELETE_USER_DATA: "users-deleteUsersData-delete",
  CHANGE_USER_STATUS: "users-changeUsersStatus-post",
  STATUS_ADMIN_USER_DATA: "users-statusAdminUsersData-post",
  USER_DETAILS_DOWNLOAD: "users-userDetailsDownload-get",

  //clientDashboard
  CLIENT_DASHBOARD_DETAILS_DOWNLOAD: "users-clientDashboardDownload-get",

  //client
  CLIENT_LIST_CHANGE_STATUS: "client-clientStatus-post",
  CLIENT_LIST_DELETE: "client-list-delete",
  ADD_CLIENT_LIST: "client-addClientList-post",
  EDIT_CLIENT_LIST: "client-editClientList-put",
  DETAIL_CLIENT_LIST: "client-detailClientList-get",
  CLIENT_CHANGE_PASSWORD: "client-change-password-post",
  CLIENT_LIST_IMPORT: "client-list-import-get",
  CLIENT_LIST_CSV_DOWNLOADS: "client-list-csv-downloads-get",
  CLIENT_VERIFY_PAYMENT: "client-verify-payment-put",
  UPDATE_SUBSCRIPTION_LIST: "client-update-SubscriptionList-post",

  //Comments
  ADD_COMMENTS_DATA: "users-addCommentsData-post",
  DETAIL_COMMENTS_LIST: "client-detailCommentsList-get",

  //contactUsers
  CONTACT_LIST_DELETE: "contactUsers-list-delete",
  ADD_CONTACT_LIST: "contactUsers-addUsersList-post",
  EDIT_CONTACT_LIST: "contactUsers-editUsersList-put",
  DETAIL_CONTACT_LIST: "contactUsers-detailUsersList-get",
  MERGE_CONTACT_LIST: "contactUsers-mergeUsersList-get",
  VIEW_MERGE_DETAIL_CONTACT_LIST: "contactUsers-viewmergeUsersList-get",
  CONTACT_LIST_DOWNLOAD: "contactUsers-list-download-get",
  CONTACT_LIST_IMPORT: "contactUsers-list-import-get",
  CONTACT_LIST_CSV_DOWNLOADS: "contact-list-csv-downloads-get",

  //portfolioUsers
  EDIT_PORTFOLIO_USERS_DATA: "portfolioUsers-addPortfolioUsersData-put",

  //subscription
  ADD_SUBSCRIPTION: "subscription-addSubscription-post",
  EDIT_SUBSCRIPTION: "subscription-editSubscription-put",
  DEMO_SUBSCRIPTION: "subscription-demoSubscription-put",
  SUBSCRIPTION_LIST: "subscription-SubscriptionList-post",
  SUBSCRIPTION_DETAILS_LIST: "subscription-SubscriptiondetailsList-get",
  DELETE_SUBSCRIPTION: "subscription-deleteSubscription-delete",
  SUBSCRIPTION_DETAILS_DOWNLOAD: "subscription-SubscriptionDownload-get",

  //static page
  STATIC_PAGE_LISTING: "static-staticPageListing-post",
  STATIC_PAGE_CHANGE_STATUS: "static-staticPageChangeStatus-post",
  ADD_STATIC_PAGES: "static-addStaticPages-post",
  EDIT_STATIC_PAGES: "static-editStaticPages-post",
  DELETE_STATIC_PAGES: "static-deleteStaticPages-delete",
  STATIC_PAGE_LIST_DOWNLAOD: "static-staticpageDownload-get",
  //filter
  SAVE_FILTER_DATA: "filter-saveFilter-post",
  GET_FILTER_DATA: "filter-getFilter-post",
  DELETE_FILTER_DATA: "filter-deleteFilter-delete",
  SAVE_COLUMN_DATA: "column-saveColumn-post",
  GET_COLUMN_DATA: "column-getColumn-post",
  DELETE_COLUMN_DATA: "column-deleteColumn-delete",
  //support
  SUPPORT_LISTING: "support-getSupportList-get",
  SUPPORT_DELETE: "support-deleteSupport-delete",
  SUPPORT_STATUS: "support-ticketStatus-patch",
  SUPPORT_ADD_DATA: "support-addSupportData-post",
  SUPPORT_EDIT_DATA: "support-editReplyData-put",
  SUPPORT_REPLY_DELETE: "support-deleteReplyData-delete",

  //main Banner
  ADD_WEB_MAIN_BANNER: "web-main-banner-addWebMainBannerList-post",
  EDIT_WEB_MAIN_BANNER: "web-main-banner-editWebMainBannerList-put",
  ADD_MOBILE_MAIN_BANNER: "mobile-main-banner-addMobileMainBannerList-post",
  EDIT_MOBILE_MAIN_BANNER: "mobile-main-banner-editMobileMainBannerList-put",

  //Benefits
  BENEFITS_LIST_CHANGE_STATUS: "Benefits-BenefitsStatus-post",
  BENEFITS_LIST_DELETE: "Benefits-list-delete",
  ADD_BENEFITS_LIST: "Benefits-addBenefitsList-post",
  EDIT_BENEFITS_LIST: "Benefits-editBenefitsList-put",
  DETAIL_BENEFITS_LIST: "Benefits-detailBenefitsList-get",

  //Capabilities
  CAPABILITIES_LIST_CHANGE_STATUS: "Capabilities-CapabilitiesStatus-post",
  CAPABILITIES_LIST_DELETE: "Capabilities-list-delete",
  ADD_CAPABILITIES_LIST: "Capabilities-addCapabilitiesList-post",
  EDIT_CAPABILITIES_LIST: "Capabilities-editCapabilitiesList-put",
  DETAIL_CAPABILITIES_LIST: "Capabilities-detailCapabilitiesList-get",

  //HowWeWork
  HOWWEWORK_LIST_CHANGE_STATUS: "HowWeWork-HowWeWorkStatus-post",
  HOWWEWORK_LIST_DELETE: "HowWeWork-list-delete",
  ADD_HOWWEWORK_LIST: "HowWeWork-addHowWeWorkList-post",
  EDIT_HOWWEWORK_LIST: "HowWeWork-editHowWeWorkList-put",
  DETAIL_HOWWEWORK_LIST: "HowWeWork-detailHowWeWorkList-get",

  //Partners
  PARTNERS_LIST_CHANGE_STATUS: "Partners-PartnersStatus-post",
  PARTNERS_LIST_DELETE: "Partners-list-delete",
  ADD_PARTNERS_LIST: "Partners-addPartnersList-post",
  EDIT_PARTNERS_LIST: "Partners-editPartnersList-put",
  DETAIL_PARTNERS_LIST: "Partners-detailPartnersList-get",

  //Testimonials
  TESTIMONIALS_LIST_CHANGE_STATUS: "Testimonials-TestimonialsStatus-post",
  TESTIMONIALS_LIST_DELETE: "Testimonials-list-delete",
  ADD_TESTIMONIALS_LIST: "Testimonials-addTestimonialsList-post",
  EDIT_TESTIMONIALS_LIST: "Testimonials-editTestimonialsList-put",
  DETAIL_TESTIMONIALS_LIST: "Testimonials-detailPartnersList-get",
};
