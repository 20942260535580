import React from "react";
import { Navigate } from "react-router-dom";

import { PATHS } from "configs/routeConfig";
import { useAuthHook } from "hooks/auth/useAuthHook";

/**
 * Guest Route
 */
export default function GuestRoute({ children }) {
  const { access_token } = useAuthHook();

  if (access_token) {
    return <Navigate to={PATHS.DASHBOARD} />;
  }

  return children;
}
