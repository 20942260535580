import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const userApi = createApi({
  reducerPath: REDUCER_PATHS.USERS,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getAdminManagement: builder.query({
      query: () => ({
        url: `${apiConfig.GET_ADMIN_MANAGEMENT_LIST}`,
        method: "GET",
      }),
    }),
    adminManagementListing: builder.query({
      query: (data) => ({
        // url: `${apiConfig.LISTING_ADMIN_MANAGEMENT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&filter=${data?.filter}`,
        url: `${apiConfig.LISTING_ADMIN_MANAGEMENT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),
    addAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editAdminUser: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ADMIN_MANAGEMENT}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    statusChangeAdminUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADMIN_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    detailsAdminUser: builder.query({
      query: (data) => ({
        url: `${apiConfig.ADMIN_MANAGEMENT}/${data.id}`,
        method: "GET",
      }),
    }),
    userManagementListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.LISTING_USER_MANAGEMENT}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}&filter=${data?.filter}`,
        method: "GET",
      }),
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data?.postData || data,
        method: "POST",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT + "/" + data?.userId,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    changeUserStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.USER_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["userDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    userDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.USER_MANAGEMENT}/${data?.id}`,
        method: "GET",
      }),
      providesTags: ["userDetails"],
    }),
    userDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.USER_MANAGEMENT_DOWNLOAD}/${data.type}?languageId=${data?.languageId}&filter=${data.filter}&column=${data.column}`,
        method: "GET",
      }),
    }),
    adminUserDetailsDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.ADMIN_USERS_DOWNLOAD}?page=${data.page}&pageSize=${data.pageSize}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),

    //Client User

    getClientList: builder.query({
      query: () => ({
        url: `${apiConfig.GET_CLIENT_DROPDOWN}`,
        method: "GET",
      }),
      providesTags: ["client"],
    }),

    clientList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.GET_CLIENT_REQUEST}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&filters=${data.filters}`,
        method: "GET",
      }),
      providesTags: ["client"],
    }),
    clientListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CLIENT_STATUS_CHANGE,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["client", "clientListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    clientListDelete: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CLIENT_REQUEST_DELETE}`,
        method: "DELETE",
        body: data,
      }),

      invalidatesTags: ["client", "clientListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addClientList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_CLIENT_REQUEST,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["client"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editClientList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CLIENT_REQUEST_UPDATE}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["client"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    clientDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_CLIENT_REQUEST}?id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["clientDetails", "client"],
    }),
    verifypayment: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.VERIFY_PAYMENT}?organizationId=${data.organizationId}`,
        body: data?.PostData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      invalidatesTags: ["clientDetails", "client"],
    }),
    updateSubscription: builder.mutation({
      query: (data) => ({
        url: apiConfig.UPDATE_SUBSCRIPTION,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["client", "History"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    clientDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DOWNLOADS_CLIENT_DASHBOARD}?page=${data.page}&pageSize=${data.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),
    ChangeClientPass: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CHANGE_CLIENT_PW}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    ImportClientList: builder.mutation({
      query: (data) => ({
        url: apiConfig.IMPORT_CLIENT_LIST,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["client"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    clientHistortDetails: builder.query({
      query: ({ data }) => ({
        url: `${apiConfig.HISTORY_CLIENT_LIST}/${data}`,
        method: "GET",
      }),
      providesTags: ["clientDetails", "History"],
    }),
    clientSampleCsvDownload: builder.mutation({
      query: () => ({
        url: `${apiConfig.DOWNLOADS_CSV_CLIENT_LIST}`,
        method: "GET",
      }),
    }),

    //Client Dashboard
    clientDashboardList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.GET_CLIENT_DASHBOARD}?page=${data.page}&pageSize=${data.pageSize}&type=${data.type}&filters=${data.filters}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}`,
        method: "GET",
      }),
      providesTags: ["clientDashboard"],
    }),
    clientDashboardDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.DOWNLOADS_CLIENT_DASHBOARD}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&filters=${data.filters}&type=${data.type}`,
        method: "GET",
      }),
    }),

    //Portfolio User

    contactList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.GET_CONTACT_USERS}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&filters=${data.filters}`,
        method: "GET",
      }),
      providesTags: ["contact"],
    }),

    contactListDelete: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CONTACT_USERS_DELETE}`,
        method: "DELETE",
        body: data,
      }),

      invalidatesTags: ["contact", "contactListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addContactList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_CONTACT_USERS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contact"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editContactList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CONTACT_USERS_UPDATE}/${data?.id}`,
        body: data?.PostData,
        method: "PUT",
      }),
      invalidatesTags: ["contact"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    contactDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_CONTACT_USERS}?id=${data.id}&type=${data?.type}`,
        method: "GET",
      }),
      providesTags: ["contact"],
    }),
    contactSingleDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.GET_CONTACT_USERS}?id=${data.id}&type=${data?.type}`,
        method: "GET",
      }),
      providesTags: ["contact"],
    }),
    contactDownload: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CONTACT_USERS_DOWNLOADS}?page=${data.page}&pageSize=${data.pageSize}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&searchText=${data?.searchText}&filters=${data?.filters}`,
        method: "GET",
      }),
    }),
    ImportContactList: builder.mutation({
      query: (data) => ({
        url: apiConfig.IMPORT_CONTACT_LIST,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["contact"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    mergeContactList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.MERGE_CONTACT_LIST}`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: ["contact"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    contactSampleCsvDownload: builder.mutation({
      query: () => ({
        url: `${apiConfig.DOWNLOADS_CSV_CONTACT_LIST}`,
        method: "GET",
      }),
    }),

    //comments
    commentDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.COMMENT_USERS_UPDATE}/${data}`,
        method: "GET",
      }),
      providesTags: ["commentDetails"],
    }),
    addCommentList: builder.mutation({
      query: (data) => ({
        url: apiConfig.ADD_COMMENT_USERS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["comment", "commentDetails"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    // Portfolio Configuration

    getPortfolioConfigurationDetails: builder.query({
      query: () => ({
        url: `${apiConfig.GET_PORTFOLIO_DETAILS}`,
        method: "GET",
      }),
      providesTags: ["portfolioConfiguration"],
    }),

    editPortfolioConfiguration: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.UPDATE_PORTFOLIO_CONFIGURATION}`,
        body: data,
        method: "PUT",
      }),
      invalidatesTags: ["portfolioConfiguration"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyGetAdminManagementQuery,
  useLazyAdminManagementListingQuery,
  useAddAdminUserMutation,
  useDeleteAdminUserMutation,
  useStatusChangeAdminUserMutation,
  useDetailsAdminUserQuery,
  useLazyUserManagementListingQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useChangeUserStatusMutation,
  useLazyUserDetailsQuery,
  useUserDetailsDownloadMutation,
  useAdminUserDetailsDownloadMutation,
  useEditUserMutation,
  useEditAdminUserMutation,

  //client

  // useGetClientListQuery,
  useLazyGetClientListQuery,
  useLazyClientListQuery,
  useClientListChangeStatusMutation,
  useClientListDeleteMutation,
  useAddClientListMutation,
  useEditClientListMutation,
  useClientDetailsQuery,
  useLazyContactSingleDetailsQuery,
  useClientDownloadMutation,
  useChangeClientPassMutation,
  useImportClientListMutation,
  useClientHistortDetailsQuery,
  useClientSampleCsvDownloadMutation,

  //contact
  useLazyContactListQuery,
  useContactListDeleteMutation,
  useAddContactListMutation,
  useEditContactListMutation,
  useLazyContactDetailsQuery,
  useContactDownloadMutation,
  useImportContactListMutation,
  useMergeContactListMutation,
  useContactSampleCsvDownloadMutation,
  //comments

  useAddCommentListMutation,
  useLazyCommentDetailsQuery,

  //client Dashboard
  useLazyClientDashboardListQuery,
  useClientDashboardDownloadMutation,

  //portfolio configuration

  useGetPortfolioConfigurationDetailsQuery,
  useEditPortfolioConfigurationMutation,

  //verify payment

  useVerifypaymentMutation,
  useUpdateSubscriptionMutation,
} = userApi;

export default userApi;
