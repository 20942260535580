import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { DEFAULT_LANGUAGE_CODE } from "utils/constants";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const blogApi = createApi({
  reducerPath: REDUCER_PATHS.BLOG,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    blogCategoryListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.BLOG_CATEGORY_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["blogCategoryListing"],
    }),
    blogCategoryStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG_CATEGORY,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: [
        "BlogCategoryDetails",
        "BlogCategoriesList",
        "blogCategoryListing",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogCategoryDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG_CATEGORY,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["BlogCategoriesList", "blogCategoryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogCategoryAdd: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG_CATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: [
        "BlogCategoryDetails",
        "BlogCategoriesList",
        "blogCategoryListing",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogCategoryEdit: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BLOG_CATEGORY}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: [
        "BlogCategoryDetails",
        "BlogCategoriesList",
        "blogCategoryListing",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    listBlogCategory: builder.query({
      query: (lang) => ({
        url: `${apiConfig.BLOG_CATEGORY_LIST}?languageId=${lang}`,
        method: "GET",
      }),
      providesTags: ["BlogCategoriesList"],
    }),
    blogCategoryDownload: builder.mutation({
      query: (data) => ({
        headers: {
          language: data.language || DEFAULT_LANGUAGE_CODE,
        },
        url: `${apiConfig.BLOG_CATEGORY_DOWNLOAD}/${data.type}`,
        method: "GET",
      }),
    }),
    staticManagementListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["staticPageListing"],
    }),
    staticManagementChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addStaticManagement: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data?.postData,
        method: "POST",
      }),
      invalidatesTags: ["StaticPageDetails", "staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editStaticManagement: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["StaticPageDetails", "staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    deleteStaticManagement: builder.mutation({
      query: (data) => ({
        url: apiConfig.STATIC_PAGE_MANAGEMENT,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["staticPageListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    staticManagementViewDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT}?staticCode=${data?.staticCode}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["StaticPageDetails", "staticPageListing"],
    }),
    staticPageManagementDownload: builder.mutation({
      query: (data) => ({
        headers: {
          language: data.language || DEFAULT_LANGUAGE_CODE,
        },
        url: `${apiConfig.STATIC_PAGE_MANAGEMENT_DOWNLOAD}/${data.type}`,
        method: "GET",
      }),
    }),
    blogCategoryDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.BLOG_CATEGORY}?blogCategoryCode=${data.blogCategoryCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["BlogCategoryDetails"],
    }),
    blogList: builder.query({
      query: (data) => ({
        url: `${apiConfig.BLOG_LIST}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["blogList"],
    }),
    addBlog: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["BlogDetails", "blogList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editBlog: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BLOG}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["BlogDetails", "blogList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["blogList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogPublishStatusChange: builder.mutation({
      query: (data) => ({
        url: apiConfig.BLOG_PUBLISH,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["BlogDetails", "blogList"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    blogDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.BLOG}?blogCode=${data.blogCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["BlogDetails"],
    }),
    blogListDownload: builder.mutation({
      query: (data) => ({
        headers: {
          language: data.language || DEFAULT_LANGUAGE_CODE,
        },
        url: `${apiConfig.BLOG_LIST_DOWNLOAD}/${data.type}`,
        method: "GET",
      }),
    }),
    faqCategoryListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.FAQ_CATEGORY_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["faqCategoryListing"],
    }),
    faqCategoryDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ_CATEGORY,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["faqCategoryListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqCategoryAdd: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ_CATEGORY,
        body: data,
        method: "POST",
      }),
      invalidatesTags: [
        "faqCategoryDetails",
        "faqCategoryListing",
        "faqCategoryList",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqCategoryEdit: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.FAQ_CATEGORY}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: [
        "faqCategoryDetails",
        "faqCategoryListing",
        "faqCategoryList",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqCategoryDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.FAQ_CATEGORY}?faqCategoryCode=${data.faqCategoryCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["faqCategoryDetails"],
    }),
    faqCategoryList: builder.query({
      query: () => ({
        url: apiConfig.FAQ_CATEGORY_LIST,
        method: "GET",
      }),
      providesTags: ["faqCategoryList"],
    }),
    faqCategoryStatusChange: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ_CATEGORY,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: [
        "faqCategoryDetails",
        "faqCategoryListing",
        "faqCategoryList",
      ],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqListing: builder.query({
      query: (data) => ({
        url: `${apiConfig.FAQ_LISTING}?page=${data?.page}&pageSize=${data?.pageSize}&searchText=${data?.searchText}&sortColumn=${data?.sortColumn}&sortDirection=${data?.sortDirection}&languageId=${data?.languageId}`,
        method: "GET",
      }),
      providesTags: ["faqListing"],
    }),
    faqAdd: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["faqDetails", "faqListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqEdit: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.FAQ}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["faqDetails", "faqListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ,
        body: data,
        method: "DELETE",
      }),
      invalidatesTags: ["faqListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    faqDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.FAQ}?faqCode=${data.faqCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["faqDetails"],
    }),
    faqStatusChange: builder.mutation({
      query: (data) => ({
        url: apiConfig.FAQ,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["faqDetails", "faqListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
  }),
});

export const {
  useLazyBlogCategoryListingQuery,
  useBlogCategoryStatusMutation,
  useLazyStaticManagementListingQuery,
  useAddStaticManagementMutation,
  useEditStaticManagementMutation,
  useDeleteStaticManagementMutation,
  useLazyStaticManagementViewDetailsQuery,
  useStaticManagementChangeStatusMutation,
  useBlogCategoryDeleteMutation,
  useBlogCategoryAddMutation,
  useListBlogCategoryQuery,
  useLazyBlogCategoryDetailsQuery,
  useLazyBlogListQuery,
  useAddBlogMutation,
  useBlogDeleteMutation,
  useLazyBlogDetailsQuery,
  useBlogPublishStatusChangeMutation,
  useBlogCategoryDownloadMutation,
  useStaticPageManagementDownloadMutation,
  useBlogListDownloadMutation,
  useBlogCategoryEditMutation,
  useEditBlogMutation,
  useLazyFaqCategoryListingQuery,
  useFaqCategoryDeleteMutation,
  useFaqCategoryAddMutation,
  useFaqCategoryEditMutation,
  useLazyFaqCategoryDetailsQuery,
  useFaqCategoryListQuery,
  useLazyFaqListingQuery,
  useFaqAddMutation,
  useFaqEditMutation,
  useFaqDeleteMutation,
  useLazyFaqDetailsQuery,
  useFaqStatusChangeMutation,
  useFaqCategoryStatusChangeMutation,
} = blogApi;

export default blogApi;
