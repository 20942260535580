export const PATHS = {
  // Default
  DEFAULT: "/",

  // Auth
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  // OTP_DETAILS: "/otp-details",
  //  OTP_VERIFICATION: "/otp-verification",

  // Dashboard Module
  DASHBOARD: "/dashboard",

  // User management
  USER: "/users",
  CLIENTSDASHBOARD: "client-dashboard",
  TODAYS_NEW_CLIENT: "todays-new-client",
  ACTIVE_CLIENT: "active-client",
  POTENTIAL_CLIENT: "potential-client",
  CLIENT_DETAILS: "client-details",
  CLIENTS: "clients",
  PORTFOLIO_USERS: "portfolio-users",
  PORTFOLIO_REFERAL_SOURCE: "portfolio-referalsource-data",
  PORTFOLIO_CONFIGURATION: "portfolio-configuration",
  USER_LIST: "user-list",
  ADMIN_USER: "admin-users",
  ADMIN_ROLES: "admin-role",

  // Demos
  DEMOS: "/demo",
  DEMO_REQUEST: "demo-requests",
  BOOKED_MEETING: "booked-meeting",
  DEMO_USERS: "demo-users",
  NEW_USERS: "new-users",
  DEMO_CONFIGURATION: "demo-configuration",
  MEETING_CONFIGURATION: "demo-configuration",
  SCHEDULE_DEMO: "scheduled-demos",
  SCHEDULE_MEETING: "scheduled-meeting",

  // Content management
  CONTENT: "/content",
  STATIC_PAGES: "static-pages",
  BLOGS: "blogs",
  BLOG_LIST: "blog-list",
  BLOG_CATEGORY: "blog-category",
  BLOG_CATEGORY_VIEW: "/blog-category-details/:id",
  FAQS: "faqs",
  FAQS_LIST: "faq-list",
  FAQS_CATEGORY: "faq-category",

  // Payment
  PAYMENT: "/payment",

  // Role
  ROLE: "/role",

  // Email And Notification
  EMAIL_TEMPLATES: "/email-templates",

  // Master management
  MASTER_MANAGEMENT: "/master",
  COUNTRY: "country",
  CURRENCY: "currency",
  TIMEZONE: "timezone",
  LANGUAGE: "language",
  INDUSTRY: "Industry",
  ORGANIZATIONSIZE: "organization-size",
  DOMAIN_OF_WORK: "domain-of-work",
  MEANS_OF_TRANSPORTATION: "means-of-transportation",
  ORGANIZATION_GOAL: "organization-goal",
  ORGANIZATION_DEVICE: "organization-device",
  REFERRAL_SOURCE: "referral-source",
  CIVIL_STATUS: "civil-status",
  CONTRACT_TYPE: "contract-type",
  WORK_ARRANGEMENT: "work-arrangement",
  JOB_POSITION: "job-position",
  EMPLOYEE_TYPE: "employee-type",
  BREAKS_NAME: "break-name",
  DEDUCTIONS: "deductions",

  // Subscription Packages
  SUBSCRIPTION_PACKAGES: "/subscription-packages",

  // Support
  SUPPORT: "/support",

  // Settings
  SETTINGS: "/settings",
  GENERAL_SETTINGS: "general-settings",
  SOCIAL_MEDIA: "social-media",
  PAYMENT_GATEWAY: "payment-gateway",
  SMTP_SMS_DETAILS: "smtp-details",
  EMAIL_NOTIFICATIONS: "email-notifications",
  LANGUAGE_MESSAGES: "language/messages",
  LANGAUGE_STATIC_LANG: "language/static-label",

  // Home Page CMS
  HOMEPAGECMS: "/cms",
  MAIN_BANNER: "main-banner",
  PARTNERS: "partners",
  BENEFITS: "benefits",
  HOW_TO_WORK: "how-we-work",
  TESTIMONIALS: "testimonials",
  CAPABILITIES: "Capabilities",

  // Common Pages
  FourZeroFour: "/common/404",
  THANKYOU: "/common/thankyou",
  COMING_SOON: "/common/coming-soon",

  // Profile
  MY_PROFILE: "/profile",
  EDIT_MY_PROFILE: "/profile/edit-profile",

  // Change Password
  CHANGE_PASSWORD: "/change-password",

  // Ui Kit
  UI_KIT: "/uikit",
  TYPOGRAPHY: "typography",
  COLORS: "colors",
  FORMS: "forms",
  CARDS: "cards",
  BLOCKS: "blocks",
  // Extras
  ANY: "*",
  VIEW: "view",
  CREATE: "create",
  EDIT: "edit",
};
