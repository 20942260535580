import { PERMISSION_SCOPES } from "components/layout/AccessControl";

import { PATHS } from "./routeConfig";

export const MenuTypes = {
  GROUP: "group",
  ITEM: "item",
  COLLAPSE: "collapse",
  COLLAPSE_LIST: "collapse-list",
};

export const MenuList = [
  {
    title: "Main",
    type: MenuTypes.GROUP,
    items: [
      {
        title: "Dashboard",
        type: MenuTypes.ITEM,
        role: PERMISSION_SCOPES.Dashboard,
        badge: 4,
        badgeBg: "primary",
        badgeClassName: "badge-numeric",
        href: PATHS.DASHBOARD,
        icon: "inic inic-home",
      },
      {
        title: "Users",
        type: MenuTypes.COLLAPSE,
        role: [
          PERMISSION_SCOPES.Clients,
          PERMISSION_SCOPES.Portfolio_user,
          PERMISSION_SCOPES.Admin_User,
        ],
        icon: "inic inic-person",
        eventKey: PATHS.USER,
        items: [
          {
            title: "Client",
            type: MenuTypes.COLLAPSE,
            role: [PERMISSION_SCOPES.Clients],
            // icon: "inic inic-person",
            eventKey: PATHS.USER,
            items: [
              {
                title: "Clients Dashboard",
                role: PERMISSION_SCOPES.Clients,
                type: MenuTypes.ITEM,
                href: `${PATHS.USER}/${PATHS.CLIENTSDASHBOARD}`,
                // linkClassName: "text-black",
              },
              // {
              //   title: "Client Details",
              //   role: PERMISSION_SCOPES.Client_Details,
              //   type: MenuTypes.ITEM,
              //   href: `${PATHS.USER}/${PATHS.CLIENT_DETAILS}`,
              //   // linkClassName: "text-black",
              // },
              {
                title: "Client",
                role: PERMISSION_SCOPES.Clients,
                type: MenuTypes.ITEM,
                href: `${PATHS.USER}/${PATHS.CLIENTS}`,
                // linkClassName: "text-black",
              },
            ],
          },
          {
            title: "Portfolio Users",
            role: PERMISSION_SCOPES.Portfolio_user,
            type: MenuTypes.ITEM,
            href: `${PATHS.USER}/${PATHS.PORTFOLIO_USERS}`,
            // linkClassName: "text-black",
          },
          {
            title: "Admin Users",
            role: PERMISSION_SCOPES.Admin_User,
            type: MenuTypes.ITEM,
            href: `${PATHS.USER}/${PATHS.ADMIN_USER}`,
            // linkClassName: "text-black",
          },
        ],
      },
      {
        title: "Demos",
        type: MenuTypes.COLLAPSE,
        role: [
          PERMISSION_SCOPES.Demo_Request,
          PERMISSION_SCOPES.Booking_Meeting,
          PERMISSION_SCOPES.Demo_Users,
        ],
        icon: "inic inic-videocam",
        eventKey: PATHS.DEMOS,
        items: [
          {
            title: "Access Demo Request",
            role: PERMISSION_SCOPES.Demo_Request,
            type: MenuTypes.ITEM,
            href: `${PATHS.DEMOS}/${PATHS.DEMO_REQUEST}`,
            // linkClassName: "text-black",
          },
          {
            title: "Booked Meeting",
            role: PERMISSION_SCOPES.Booking_Meeting,
            type: MenuTypes.ITEM,
            href: `${PATHS.DEMOS}/${PATHS.BOOKED_MEETING}`,
            // linkClassName: "text-black",
          },
          {
            title: "Demo Users",
            role: PERMISSION_SCOPES.Demo_Users,
            type: MenuTypes.ITEM,
            href: `${PATHS.DEMOS}/${PATHS.DEMO_USERS}`,
            // linkClassName: "text-black",
          },
        ],
      },
      {
        title: "Content",
        type: MenuTypes.COLLAPSE,
        role: [
          PERMISSION_SCOPES.Static_pages,
          PERMISSION_SCOPES.Email_Template,
        ],
        eventKey: PATHS.CONTENT,
        icon: "inic inic-file",
        items: [
          {
            title: "Static Pages",
            role: PERMISSION_SCOPES.Static_pages,
            type: MenuTypes.ITEM,
            href: `${PATHS.CONTENT}/${PATHS.STATIC_PAGES}`,
            // linkClassName: "text-black",
          },
          {
            title: "Email Templates",
            role: PERMISSION_SCOPES.Email_Template,
            type: MenuTypes.ITEM,
            href: PATHS.EMAIL_TEMPLATES,
            badge: 4,
            badgeBg: "primary",
            badgeClassName: "badge-numeric",
          },
          // {
          //   title: "Blogs",
          //   role: PERMISSION_SCOPES.Blog,
          //   type: MenuTypes.COLLAPSE_LIST,
          //   items: [
          //     {
          //       title: "Blog List",
          //       role: PERMISSION_SCOPES.Blog,
          //       type: MenuTypes.ITEM,
          //       href: `${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_LIST}`,
          //       // linkClassName: "text-black",
          //     },
          //     {
          //       title: "Blog Category",
          //       role: PERMISSION_SCOPES.Blog,
          //       type: MenuTypes.ITEM,
          //       href: `${PATHS.CONTENT}/${PATHS.BLOGS}/${PATHS.BLOG_CATEGORY}`,
          //       // linkClassName: "text-black",
          //     },
          //   ],
          // },
          // {
          //   title: "FAQs",
          //   role: PERMISSION_SCOPES.Faq,
          //   type: MenuTypes.COLLAPSE_LIST,
          //   items: [
          //     {
          //       title: "FAQ List",
          //       role: PERMISSION_SCOPES.Faq,
          //       type: MenuTypes.ITEM,
          //       href: `${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_LIST}`,
          //       // linkClassName: "text-black",
          //     },
          //     {
          //       title: "FAQ Category",
          //       role: PERMISSION_SCOPES.Faq,
          //       type: MenuTypes.ITEM,
          //       href: `${PATHS.CONTENT}/${PATHS.FAQS}/${PATHS.FAQS_CATEGORY}`,
          //       // linkClassName: "text-black",
          //     },
          //   ],
          // },
        ],
      },
      // {
      //   title: "Payment",
      //   type: MenuTypes.ITEM,
      //   role: PERMISSION_SCOPES.Payment,
      //   href: PATHS.PAYMENT,
      //   icon: "inic inic-payment",
      // },
      {
        title: "Role & Permissions",
        type: MenuTypes.ITEM,
        role: PERMISSION_SCOPES.Role,
        href: PATHS.ROLE,
        badge: "New",
        badgeBg: "secondary",
        badgeClassName: "badge-text",
        icon: "inic inic-circle-tick",
      },
    ],
  },
  {
    title: "General",
    type: MenuTypes.GROUP,
    items: [
      {
        title: "Master",
        role: PERMISSION_SCOPES.Master,
        type: MenuTypes.COLLAPSE,
        icon: "inic inic-crown",
        eventKey: PATHS.MASTER_MANAGEMENT,
        items: [
          {
            title: "Language",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.LANGUAGE}`,
            // linkClassName: "text-black",
          },
          {
            title: "Industry",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.INDUSTRY}`,
            // linkClassName: "text-black",
          },
          {
            title: "Organization Size",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATIONSIZE}`,
            // linkClassName: "text-black",
          },
          {
            title: "Domain Of Work",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.DOMAIN_OF_WORK}`,
            // linkClassName: "text-black",
          },
          // {
          //   title: "Means Of Transportation",
          //   role: PERMISSION_SCOPES.Master,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.MEANS_OF_TRANSPORTATION}`,
          //   // linkClassName: "text-black",
          // },

          {
            title: "Country",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.COUNTRY}`,
            // linkClassName: "text-black",
          },
          {
            title: "Currency",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.CURRENCY}`,
            // linkClassName: "text-black",
          },
          {
            title: "Organization Goals",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATION_GOAL}`,
            // linkClassName: "text-black",
          },

          {
            title: "Organization Device",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.ORGANIZATION_DEVICE}`,
            // linkClassName: "text-black",
          },

          {
            title: "Referral Source",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.REFERRAL_SOURCE}`,
            // linkClassName: "text-black",
          },

          {
            title: "Civil Status",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.CIVIL_STATUS}`,
            // linkClassName: "text-black",
          },

          {
            title: "Contract Type",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.CONTRACT_TYPE}`,
            // linkClassName: "text-black",
          },

          // {
          //   title: "Work Arrangement",
          //   role: PERMISSION_SCOPES.Master,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.WORK_ARRANGEMENT}`,
          //   // linkClassName: "text-black",
          // },
          {
            title: "Job Position",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.JOB_POSITION}`,
            // linkClassName: "text-black",
          },
          {
            title: "Employee Type",
            role: PERMISSION_SCOPES.Master,
            type: MenuTypes.ITEM,
            href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.EMPLOYEE_TYPE}`,
            // linkClassName: "text-black",
          },

          // {
          //   title: "Break Name",
          //   role: PERMISSION_SCOPES.Master,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.BREAKS_NAME}`,
          //   // linkClassName: "text-black",
          // },

          // {
          //   title: "Deductions",
          //   role: PERMISSION_SCOPES.Master,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.DEDUCTIONS}`,
          //   // linkClassName: "text-black",
          // },
          // {
          //   title: "Timezone",
          //   role: PERMISSION_SCOPES.Master,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.MASTER_MANAGEMENT}/${PATHS.TIMEZONE}`,
          //   linkClassName: "text-black",
          // },
        ],
      },
      {
        title: "Subscription Packages",
        type: MenuTypes.ITEM,
        role: PERMISSION_SCOPES.Subscription,
        href: PATHS.SUBSCRIPTION_PACKAGES,
        icon: "inic inic-gift",
      },
      // {
      //   title: "Support",
      //   type: MenuTypes.ITEM,
      //   role: PERMISSION_SCOPES.Support,
      //   href: PATHS.SUPPORT,
      //   icon: "inic inic-support",
      // },
      {
        title: "Settings",
        type: MenuTypes.COLLAPSE,
        role: [
          PERMISSION_SCOPES.General_Settings,
          PERMISSION_SCOPES.Social_Media_Settings,
          PERMISSION_SCOPES.Payment_Gateway_Settings,
          PERMISSION_SCOPES.SMTP_SMS_Settings,
          // PERMISSION_SCOPES.Email_Notifications_Settings,
          PERMISSION_SCOPES.Static_Label_Settings,
        ],
        icon: "inic inic-settings",
        eventKey: PATHS.SETTINGS,
        items: [
          {
            title: "General Settings",
            role: PERMISSION_SCOPES.General_Settings,
            type: MenuTypes.ITEM,
            href: `${PATHS.SETTINGS}/${PATHS.GENERAL_SETTINGS}`,
            // linkClassName: "text-black",
          },
          {
            title: "Social Media",
            role: PERMISSION_SCOPES.Social_Media_Settings,
            type: MenuTypes.ITEM,
            href: `${PATHS.SETTINGS}/${PATHS.SOCIAL_MEDIA}`,
            // linkClassName: "text-black",
          },
          {
            title: "Payment Gateway",
            role: PERMISSION_SCOPES.Payment_Gateway_Settings,
            type: MenuTypes.ITEM,
            href: `${PATHS.SETTINGS}/${PATHS.PAYMENT_GATEWAY}`,
            // linkClassName: "text-black",
          },
          {
            title: "SMTP / SMS Details",
            role: PERMISSION_SCOPES.SMTP_SMS_Settings,
            type: MenuTypes.ITEM,
            href: `${PATHS.SETTINGS}/${PATHS.SMTP_SMS_DETAILS}`,
            // linkClassName: "text-black",
          },
          // {
          //   title: "Email Notifications",
          //   role: PERMISSION_SCOPES.Email_Notifications_Settings,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.SETTINGS}/${PATHS.EMAIL_NOTIFICATIONS}`,
          //   // linkClassName: "text-black",
          // },
          // {
          //   title: "Language Preference",
          //   role: PERMISSION_SCOPES.Static_Label_Settings,
          //   type: MenuTypes.ITEM,
          //   href: `${PATHS.SETTINGS}/${PATHS.LANGAUGE_STATIC_LANG}`,
          //   // linkClassName: "text-black",
          // },
          {
            title: "Error / Labels",
            role: PERMISSION_SCOPES.Settings,
            type: MenuTypes.COLLAPSE_LIST,
            eventKey: 0,
            items: [
              {
                title: "Error Messages",
                type: MenuTypes.ITEM,
                role: PERMISSION_SCOPES.Settings,
                href: `${PATHS.SETTINGS}/${PATHS.LANGUAGE_MESSAGES}`,
                // linkClassName: "text-black",
              },
              {
                title: "Static Label",
                type: MenuTypes.ITEM,
                role: PERMISSION_SCOPES.Settings,
                href: `${PATHS.SETTINGS}/${PATHS.LANGAUGE_STATIC_LANG}`,
                // linkClassName: "text-black",
              },
            ],
          },
        ],
      },
      {
        title: "Home Page CMS",
        type: MenuTypes.COLLAPSE,
        role: PERMISSION_SCOPES.Home_Page_Cms,
        icon: "inic inic-dashboard",
        eventKey: PATHS.HOMEPAGECMS,
        items: [
          {
            title: "Main Banner",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.MAIN_BANNER}`,
            // linkClassName: "text-black",
          },
          {
            title: "Partners",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.PARTNERS}`,
            // linkClassName: "text-black",
          },
          {
            title: "Benefits",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.BENEFITS}`,
            // linkClassName: "text-black",
          },
          {
            title: "How We Work",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.HOW_TO_WORK}`,
            // linkClassName: "text-black",
          },
          {
            title: "Testimonials",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.TESTIMONIALS}`,
            // linkClassName: "text-black",
          },
          {
            title: "Capabilities",
            role: PERMISSION_SCOPES.Home_Page_Cms,
            type: MenuTypes.ITEM,
            href: `${PATHS.HOMEPAGECMS}/${PATHS.CAPABILITIES}`,
            // linkClassName: "text-black",
          },
        ],
      },
      // {
      //   title: "Common Pages",
      //   type: MenuTypes.COLLAPSE,
      //   role: "Common Pages",
      //   icon: "bx bxs-customize",
      //   eventKey: 5,
      //   items: [
      //     {
      //       title: "404",
      //       type: MenuTypes.ITEM,
      //       href: PATHS.FourZeroFour,
      //       linkClassName: "text-black",
      //     },
      //     {
      //       title: "Thank You",
      //       type: MenuTypes.ITEM,
      //       href: PATHS.THANKYOU,
      //       linkClassName: "text-black",
      //     },
      //     {
      //       title: "Coming Soon",
      //       type: MenuTypes.ITEM,
      //       href: PATHS.COMING_SOON,
      //       linkClassName: "text-black",
      //     },
      //   ],
      // },
    ],
  },
];

export const MenuListCollapsed = [
  {
    title: "Dashboard",
    role: PERMISSION_SCOPES.Dashboard,
    icon: "inic inic-home",
    href: PATHS.DASHBOARD,
  },
  {
    title: "Users",
    role: PERMISSION_SCOPES.Users,
    icon: "inic inic-person",
    href: PATHS.USER,
  },
  {
    title: "Demos",
    role: PERMISSION_SCOPES.Demos,
    icon: "inic inic-videocam",
    href: PATHS.DEMOS,
  },
  {
    title: "Content",
    role: PERMISSION_SCOPES.Content,
    icon: "inic inic-file",
    href: PATHS.CONTENT,
  },
  {
    title: "Payment",
    role: PERMISSION_SCOPES.Payment,
    href: PATHS.PAYMENT,
    icon: "inic inic-payment",
  },
  {
    title: "Role",
    role: PERMISSION_SCOPES.Role,
    icon: "inic inic-circle-tick",
    href: PATHS.ROLE,
  },
  {
    title: "Email Templates",
    role: PERMISSION_SCOPES.Email_Template,
    icon: "inic inic-email",
    href: PATHS.EMAIL_TEMPLATES,
  },
  {
    title: "Master",
    role: PERMISSION_SCOPES.Master,
    icon: "inic inic-crown",
    href: PATHS.MASTER_MANAGEMENT,
  },
  // {
  //   title: "Support",
  //   role: PERMISSION_SCOPES.Support,
  //   icon: "inic inic-support",
  //   href: PATHS.SUPPORT,
  // },
  {
    title: "Settings",
    role: PERMISSION_SCOPES.Settings,
    icon: "inic inic-settings",
    href: PATHS.SETTINGS,
  },
  {
    title: "Home Page CMS",
    role: PERMISSION_SCOPES.Home_Page_Cms,
    icon: "inic inic-dashboard",
    href: PATHS.HOMEPAGECMS,
  },
];
