import { Buffer } from "buffer";

// import CryptoJS from "crypto-js";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import "react-toastify/dist/ReactToastify.css";
import blogimg from "assets/images/blog-img.webp";
import logo from "assets/images/logo.svg";
import noimage from "assets/images/no-img.jpeg";
import favicon from "assets/images/pointili-short-logo.svg";
import profilepic from "assets/images/profile-pic.jpg";
import apiConfig from "configs/apiConfig";
import generalConfig from "configs/generalConfig";
import { DEFAULT_DATE_FORMAT, DEFAULT_LANGUAGE_CODE } from "utils/constants";
import { localStorageKeys } from "utils/localStorageKeys";

/******************* 
@Purpose : Used for show message notification
@Parameter : text, type, autoClose, position
@Author : INIC
******************/
export const messageNotification = (
  text,
  type = "success",
  autoClose = generalConfig.TOAST_TIMER,
  position = "top-right"
) => {
  toast[type](text, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

/******************* 
@purpose : Used for encode data into base 64
@Parameter : {data} 
@Author : INIC
******************/
export const encodeBase64 = (data) => {
  return Buffer.from(data).toString("base64");
};

/******************* 
@purpose : Used for decode data from base 64
@Parameter : {data} 
@Author : INIC
******************/
export const decodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("ascii");
};

/******************* 
@purpose : Used for content type
@Parameter : {} 
@Author : IndiaNIC
******************/
export const getHeader = (formData = false) => {
  return {
    "Content-Type": formData ? "multipart/form-data" : "application/json",
    Accept: "application/json",
  };
};

/******************* 
@purpose : Used for File upload extension
@Parameter : {} 
@Author : INIC
******************/
export const FILE_UPLOAD_EXTENSION = [
  "image/png", // for .png image
  "image/jpeg", // for .jpeg image
  "image/jpg", // for .jpg image
  // ".svg", // for .svg image
];

/******************* 
@purpose : Used for Remember Me Login
@Parameter : {} 
@Author : INIC
******************/

export const rememberMe = (clearTheme = true) => {
  const rememberMe = localStorage?.getItem(localStorageKeys.REMEMBER_ME);
  if (!rememberMe) {
    localStorage.clear();
  } else {
    if (clearTheme) {
      localStorage.removeItem(localStorageKeys.THEME);
    }
    localStorage.removeItem(localStorageKeys.EXPIRATIONTIME);
    localStorage.removeItem(localStorageKeys.IS_EMAIL);
    localStorage.removeItem(localStorageKeys.IS_MOBILE);
    localStorage.removeItem(localStorageKeys.ma_la);
    localStorage.removeItem(localStorageKeys.MASKED_EMAIL);
    localStorage.removeItem(localStorageKeys.MASKED_PHONE);
    localStorage.removeItem(localStorageKeys.OTP_TOKEN);
    localStorage.removeItem(localStorageKeys?.VERIFY_LOGIN_TOKEN);
  }
};

/******************* 
@purpose : Used for show default profile image onerror
@Parameter : {} 
@Author : INIC
******************/

export const setDefaultProfilePic = (e) => {
  e.currentTarget.src = profilepic;
  e.currentTarget.onerror = null;
};

/******************* 
@purpose : Used for show default profile image onerror
@Parameter : {} 
@Author : INIC
******************/

export const setDefaultLogo = (e) => {
  e.currentTarget.src = logo;
  e.currentTarget.onerror = null;
};
export const setDefaultFavicon = (e) => {
  e.currentTarget.src = favicon;
  e.currentTarget.onerror = null;
};

export const arrayToJson = (array, key) => {
  let data = {};
  for (let obj of array) {
    data[obj[key]] = obj;
  }
  return data;
};

export const jsonToArray = (obj) => Object.values(obj);

/******************* 
@purpose : Used for formatting date 
@Parameter : {date, dateFormat} 
@Author : INIC
******************/
export const getFormattedDate = (date, dateFormat) => {
  if (date === "") {
    return false;
  }
  if (dateFormat) {
    return moment(date).format(dateFormat);
  } else {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  }
};

/******************* 
@purpose : Used for formatting date in date picker date format prop
@Parameter : {date, dateFormat} 
@Author : INIC
******************/
export const getFormattedDateWithTime = (
  dateFormat = "dd-MM-yyyy",
  timeFormat
) => {
  if (timeFormat === "12 hours") {
    return `${dateFormat} h:mm aa`;
  } else if (timeFormat === "24 hours") {
    return `${dateFormat} HH:mm`;
  } else {
    return `${dateFormat} h:mm aa`;
  }
};

/******************* 
@purpose : Used for converting date according to timezone
@Parameter : {date, timezone} 
@Author : INIC
******************/
export const getTimeZoneConvertedDate = (date, timezone) => {
  if (date === "") {
    return false;
  }
  return moment.tz(date, timezone);
};

/******************* 
@purpose : Used for converting date to utc
@Parameter : {date} 
@Author : INIC
******************/
export const getUTCFormat = (date) => {
  return moment(date).utc().format("YYYY-MM-DDTHH:mm:ss");
};

export const getFormattedTimeWithTimezone = (time, timezone, timeFormat) => {
  if (timeFormat === "12 hours") {
    return moment(time).tz(timezone).format("hh:mm A");
  } else if (timeFormat === "24 hours") {
    return moment(time).tz(timezone).format("HH:mm");
  } else {
    return moment(time).tz(timezone).format("hh:mm A");
  }
};

export const getFormattedDateTimeWithTimezone = (
  date,
  timezone,
  dateFormat,
  timeFormat
) => {
  if (timeFormat === "12 hours") {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-hh:mm A`);
  } else if (timeFormat === "24 hours") {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-HH:mm`);
  } else {
    return moment(date)?.tz(timezone)?.format(`${dateFormat}-hh:mm A`);
  }
};

/******************* 
@purpose : Used for show alert popup 
@Parameter : {} 
@Author : INIC
******************/
export const fireDeletePopup = async (
  confirmDeleteHandler,
  resetGridRowSelection,
  title
) => {
  const willDelete = await Swal.fire({
    title: title || "Are you sure, you want to delete?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    showCancelButton: true,
    confirmButtonText: "Delete",
    // cancelButtonText: "Cancel",
    // confirmButtonColor: "#A01B1B",
    // cancelButtonColor: "#999999",
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: true,
  });

  if (willDelete.isConfirmed) {
    confirmDeleteHandler();
    resetGridRowSelection && resetGridRowSelection();
  }
};

export const subscriptionfireDeletePopup = async (
  confirmDeleteHandler,
  resetGridRowSelection,
  title
) => {
  const willDelete = await Swal.fire({
    title: title || "Are you sure you want to delete?",
    text: "The plan will be deleted, but the existing subscription will remain active.",
    icon: "warning",
    buttons: true,
    dangerMode: true,
    showCancelButton: true,
    confirmButtonText: "Delete",
    // cancelButtonText: "Cancel",
    // confirmButtonColor: "#A01B1B",
    // cancelButtonColor: "#999999",
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: true,
  });

  if (willDelete.isConfirmed) {
    confirmDeleteHandler();
    resetGridRowSelection && resetGridRowSelection();
  }
};

export const clientstatusfireDeletePopup = async (
  confirmDeleteHandler,
  resetGridRowSelection,
  title
) => {
  const willDelete = await Swal.fire({
    title: title || "Are you sure you want to change status?",
    text: "The user's subscription will remain active, and the billing will be based on the selected plan. If there is a need to cancel the subscription, please manually do so from the Stripe dashboard..",
    icon: "info",
    buttons: true,
    dangerMode: true,
    showCancelButton: true,
    confirmButtonText: "Status Change",
    cancelButtonText: "Cancel",
    confirmButtonColor: "#4CAF50",
    // cancelButtonColor: "#999999",
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: true,
  });

  if (willDelete.isConfirmed) {
    confirmDeleteHandler();
    resetGridRowSelection && resetGridRowSelection();
  }
};

export const getCommercialUserOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.fullName}`,
    value: list?.id,
  }));
};
export const getAdminUserOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.firstName} ${list?.lastName}`,
    value: list?.id,
  }));
};
export const getParticipantsOptions = (data) => {
  return data?.map((list) => ({
    label: list?.email,
    value: list?.id,
  }));
};
export const getContactUserOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.contactName}`,
    value: list?.id,
  }));
};
export const getContactUserMeetingOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.email}`,
    value: list?.id,
  }));
};
export const getLanguageOptions = (data) => {
  return data?.map((list) => ({
    label:
      list?.country !== null
        ? `${list?.language}(${list?.country})`
        : `${list?.language}`,
    value: list?.id,
  }));
};
export const getIndustryOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.title}`,
    value: list?.id,
  }));
};
export const getSubscriptionOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.title}`,
    value: list?.id,
  }));
};
export const getCountryOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.countryName}`,
    value: list?.id,
  }));
};
export const getOrganizationSizeOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.organizationSize}`,
    value: list?.id,
  }));
};
export const getOrganizationNameOptions = (data) => {
  return data?.map((list) => ({
    label: list,
    value: list,
  }));
};
export const getReferralSourceOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.sourceName}`,
    value: list?.id,
  }));
};
export const getRolesOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.role}`,
    value: list?.id,
  }));
};
export const getOrganizationGoalOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.goalName}`,
    value: list?.id,
  }));
};
export const getOrganizationDeviceOptions = (data) => {
  return data?.map((list) => ({
    label: `${list?.deviceName}`,
    value: list?.id,
  }));
};
export const getLanguageCode = (data, id) => {
  return id && data
    ? data?.find((list) => list?.id === id)?.languageCode
    : DEFAULT_LANGUAGE_CODE;
};

/******************* 
@purpose : Used for setting default pic in blog card view 
@Parameter : {} 
@Author : INIC
******************/
export const setDefaultBlogImage = (e) => {
  e.currentTarget.src = blogimg;
  e.currentTarget.onerror = null;
};
/******************* 
@purpose : Used for setting no image 
@Parameter : {} 
@Author : INIC
******************/
export const setDefaultNoImage = (e) => {
  e.currentTarget.src = noimage;
  e.currentTarget.onerror = null;
};
/******************* 
@purpose : Used for select Option in react select 
@Parameter : {} 
@Author : INIC
******************/
export const selectOptionHandler = (formik, name) => {
  return formik?.values?.[name]
    ? {
        label: formik?.values?.[name],
        value: formik?.values?.[name],
      }
    : undefined;
};

/******************* 
@purpose : Used for conditional ternary 
@Parameter : {} 
@Author : INIC
******************/
export const conditionalTernaryHandler = (condition, param1, param2) => {
  return condition ? param1 : param2;
};

/******************* 
@purpose : Used for common ternary 
@Parameter : {} 
@Author : INIC
******************/
export const commonTernaryHandler = (param, param2) => {
  return param ? param : param2;
};

/******************* 
@purpose : Used for download file
@Parameter : {type,file} 
@Author : INIC
******************/
export const downlaodFiletoType = async (type, filepath) => {
  let file = `${apiConfig.IMAGE_DOWNLOAD}/${type}/${filepath}`;
  await fetch(file)
    .then((res) => res.blob())
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filepath);
      link.click();
    });
};
// function downlaodFiletoType(type, filePathAndName) {
//   // Logic to handle the file download based on the given type and file path/name
//   // You can use the provided parameters to determine the file to download and perform necessary actions

//   // Example implementation for image download:
//   const anchorElement = document.createElement("a");
//   anchorElement.href = filePathAndName; // Assuming the file path/name is a valid URL
//   anchorElement.download = `image.${type}`; // Specify the desired filename with the appropriate extension
//   anchorElement.click();
// }
// export const handleFileDownload = (data) => {
//   let name = data?.split("/").pop();
//   let file = `${apiConfig?.IMAGE_DOWNLOAD}${data}`;
//   fetch(file)
//     .then((res) => res.blob())
//     .then((data) => {
//       const url = window.URL.createObjectURL(new Blob([data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute(fileType.DOWNLOAD, name);
//       link.click();
//     });
// };
export const handleFileDownload = (data) => {
  let name = data?.split("/").pop();
  let file = `${apiConfig?.IMAGE_DOWNLOAD}${data}`;

  fetch(file)
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res.blob();
    })
    .then((data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      link.click();
    })
    .catch((error) => {
      console.error("Error fetching the file:", error);
    });
};

// export const handleFileDownload = () => {
//   let name = fileName + "." + fileType;
//   let file =
//     candidatesData.FILE_PATH &&
//     candidatesData.FILE_PATH + candidatesData.FILE_PATH &&
//     candidatesData.resume;
//   fetch(file)
//     .then((res) => res.blob())
//     .then((data) => {
//       const url = window.URL.createObjectURL(new Blob([data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", name);
//       link.click();
//     });
// };

/******************* 
@purpose : Used for sorting array of objects
@Parameter : {arr, key} 
@Author : INIC
******************/

export const sortArrOfObj = (arr, key) => {
  return arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

/******************* 
@purpose : Used for Image Show
@Parameter : {arr, key} 
@Author : INIC
******************/
export const commonImageHandler = (image) => {
  if (!image) {
    return profilepic;
  } else if (image.includes("https")) {
    return image;
  } else {
    return apiConfig.FIlE_UPLOAD + image;
  }
};

/******************* 
@purpose : Used for Google Locations
@Parameter : {arr, key} 
@Author : INIC
******************/

export const GOOGLE_PLACE = {
  fields: ["address_components", "geometry", "formatted_address"],
  types: ["geocode", "establishment"],
};

/** ****************** 
@purpose : Used for store login responce and user info
@Parameter : { data }
@Author : INIC
***************** */
// export const setTransectionkey = (data) => {
//   const info = JSON.stringify(data);
//   const text = CryptoJS.AES.encrypt(info, apiConfig?.ENCRYPTION_KEY).toString();
//   localStorage.setItem("transection_key", text);
//   return text;
// };
// /** ******************
// @purpose : Used for store login responce and user info
// @Parameter : { data }
// @Author : INIC
// ***************** */
// export const getTransectionkey = () => {
//   const text = localStorage.getItem("transection_key");
//   if (text) {
//     const info = CryptoJS.AES.decrypt(text, apiConfig?.ENCRYPTION_KEY).toString(
//       CryptoJS.enc.Utf8
//     );
//     return JSON.parse(info);
//   }
//   return {};
// };

/** ***************** 
@purpose : prevents form submission on enter
@Parameter : date
@Author : INIC
***************** */
export const handleEnterKeyPress = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

/** ***************** 
@purpose : get formated notification type
@Parameter : date
@Author : INIC
***************** */
export const formatNotificationType = (text) => {
  const words = text.split(/(?=[A-Z])/);

  const formattedText = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedText;
};
