import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { loginUserTokenAction } from "store/actions/token";
import { REDUCER_PATHS } from "store/config";
import {
  sentOtpTransformResponseHandler,
  transformResponseHandler,
} from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const authApi = createApi({
  reducerPath: REDUCER_PATHS.AUTH,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: apiConfig.LOGIN,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data?.extraMeta) {
            dispatch(
              loginUserTokenAction({ data: data.data, ...data.extraMeta })
            );
          }
        } catch (resp) {
          transformResponseHandler(resp);
        }
      },
    }),

    sendOtp: builder.mutation({
      query: (data) => ({
        url: apiConfig.SEND_OTP,
        body: data,
        method: "POST",
      }),
      transformResponse: (response) =>
        sentOtpTransformResponseHandler(response),
    }),

    verifyOtp: builder.mutation({
      query: (data) => ({
        url: apiConfig.VERIFY_OTP,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(loginUserTokenAction({ data: data.data, ...data.extraMeta }));
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: apiConfig.LOGOUT,
        method: "GET",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: apiConfig.FORGOT_PW,
        body: data,
        method: "POST",
        headers: {
          technology: "react",
        },
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    resetPass: builder.mutation({
      query: (data) => ({
        url: apiConfig.RESET_PW,
        body: data,
        method: "POST",
      }),
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    getTableColumnSettings: builder.query({
      query: () => ({
        url: apiConfig.GET_COLUMN_TABLE_SETTINGS,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useLogoutMutation,
  useResetPassMutation,
  useForgotPasswordMutation,
  useGetTableColumnSettingsQuery,
} = authApi;

export default authApi;
