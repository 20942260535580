import { createApi } from "@reduxjs/toolkit/query/react";

import apiConfig from "configs/apiConfig";
import { REDUCER_PATHS } from "store/config";
import { transformResponseHandler } from "utils/transformResponseHandler";

import { customFetchBase } from ".";

const cmsApi = createApi({
  reducerPath: REDUCER_PATHS.CMS,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    // Partners
    getPartners: builder.query({
      query: () => ({
        url: apiConfig.GET_PARTNERS,
        method: "GET",
      }),
      providesTags: ["Partners"],
    }),

    partnersList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.PARTNERS}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["partnersListing"],
    }),
    partnersListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.PARTNERS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Partners", "partnersListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    partnersListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.PARTNERS,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Partners", "partnersListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addPartnersList: builder.mutation({
      query: (data) => ({
        url: apiConfig.PARTNERS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["partnersDetails", "partnersListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editPartnersList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.PARTNERS}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["partnersDetails", "partnersListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    partnersDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.PARTNERS_DETAILS}?partnerCode=${data.partnerCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["partnersDetails"],
    }),

    // Benefits
    getBenefits: builder.query({
      query: () => ({
        url: apiConfig.GET_BENEFITS,
        method: "GET",
      }),
      providesTags: ["Benefits"],
    }),

    benefitsList: builder.query({
      query: (data) => ({
        url: `${apiConfig.BENEFITS}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["benefitsListing"],
    }),
    benefitsListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.BENEFITS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Benefits", "benefitsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    benefitsListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.BENEFITS,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Benefits", "benefitsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addBenefitsList: builder.mutation({
      query: (data) => ({
        url: apiConfig.BENEFITS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Benefits", "benefitsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editBenefitsList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.BENEFITS}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["benefitsDetails", "benefitsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    benefitsDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.BENEFITS_DETAILS}?title=${data.title}&description=${data.description}&icon=${data.icon}&benefitsCode=${data.benefitsCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["benefitsDetails"],
    }),

    // HowWeWork
    getHowWeWork: builder.query({
      query: () => ({
        url: apiConfig.GET_HOW_WE_WORK,
        method: "GET",
      }),
      providesTags: ["HowWeWork"],
    }),

    howWeWorkList: builder.query({
      query: (data) => ({
        url: `${apiConfig.HOW_WE_WORK}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["howWeWorkListing"],
    }),
    howWeWorkListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.HOW_WE_WORK,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["HowWeWork", "howWeWorkListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    howWeWorkListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.HOW_WE_WORK,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["HowWeWork", "howWeWorkListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addHowWeWorkList: builder.mutation({
      query: (data) => ({
        url: apiConfig.HOW_WE_WORK,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["howWeWorkDetails", "howWeWorkListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editHowWeWorkList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.HOW_WE_WORK}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["howWeWorkDetails", "howWeWorkListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    howWeWorkDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.HOW_WE_WORK_DETAILS}?title=${data.title}&description=${data.description}&banner=${data.banner}&hwCode=${data.hwCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["howWeWorkDetails"],
    }),

    // Testimonials
    getTestimonials: builder.query({
      query: () => ({
        url: apiConfig.GET_TESTIMONIALS,
        method: "GET",
      }),
      providesTags: ["Testimonials"],
    }),

    testimonialsList: builder.query({
      query: (data) => ({
        url: `${apiConfig.TESTIMONIALS}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["testimonialsListing"],
    }),
    testimonialsListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.TESTIMONIALS,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Testimonials", "testimonialsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    testimonialsListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.TESTIMONIALS,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Testimonials", "testimonialsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addTestimonialsList: builder.mutation({
      query: (data) => ({
        url: apiConfig.TESTIMONIALS,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["testimonialsDetails", "testimonialsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editTestimonialsList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.TESTIMONIALS}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["testimonialsDetails", "testimonialsListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    testimonialsDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.TESTIMONIALS_DETAILS}?languageId=${data.languageId}&testimonialCode=${data.testimonialCode}`,
        method: "GET",
      }),
      providesTags: ["testimonialsDetails"],
    }),

    webBannerList: builder.query({
      query: (id) => ({
        url: `${apiConfig.WEB_MAIN_BANNER}?languageId=${id}`,
        method: "GET",
      }),
      providesTags: ["webBannerListing"],
    }),
    addWebBannerList: builder.mutation({
      query: (data) => ({
        url: apiConfig.WEB_MAIN_BANNER,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddWebBannerListing", "webBannerListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editWebBannerList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.WEB_MAIN_BANNER}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["editWebBannerListing", "webBannerListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    mobileBannerList: builder.query({
      query: (id) => ({
        url: `${apiConfig.GET_WEB_MAIN_BANNER}?languageId=${id}`,
        method: "GET",
      }),
      providesTags: ["mobileBannerListing"],
    }),
    addMobileBannerList: builder.mutation({
      query: (data) => ({
        url: apiConfig.MOBILE_MAIN_BANNER,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddMobileBannerListing", "mobileBannerListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editMobileBannerList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.MOBILE_MAIN_BANNER}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["editMobileBannerListing", "mobileBannerListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),

    // Capabilities
    getCapabilities: builder.query({
      query: () => ({
        url: apiConfig.GET_CAPABILITIES,
        method: "GET",
      }),
      providesTags: ["Capabilities"],
    }),

    capabilitiesList: builder.query({
      query: (data) => ({
        headers: {
          deviceid: "web",
        },
        url: `${apiConfig.CAPABILITIES}?page=${data.page}&pageSize=${data.pageSize}&searchText=${data.searchText}&sortColumn=${data.sortColumn}&sortDirection=${data.sortDirection}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["CapabilitiesListing"],
    }),
    capabilitiesListChangeStatus: builder.mutation({
      query: (data) => ({
        url: apiConfig.CAPABILITIES,
        body: data,
        method: "PATCH",
      }),
      invalidatesTags: ["Capabilities", "CapabilitiesListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    capabilitiesListDelete: builder.mutation({
      query: (data) => ({
        url: apiConfig.CAPABILITIES,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Capabilities", "CapabilitiesListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    addCapabilitiesList: builder.mutation({
      query: (data) => ({
        url: apiConfig.CAPABILITIES,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CapabilitiesDetails", "CapabilitiesListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    editCapabilitiesList: builder.mutation({
      query: (data) => ({
        url: `${apiConfig.CAPABILITIES}/${data?.id}`,
        body: data?.postData,
        method: "PUT",
      }),
      invalidatesTags: ["CapabilitiesDetails", "CapabilitiesListing"],
      transformResponse: (resp) => transformResponseHandler(resp),
    }),
    capabilitiesDetails: builder.query({
      query: (data) => ({
        url: `${apiConfig.CAPABILITIES_DETAILS}?capabilitiesCode=${data.capabilitiesCode}&languageId=${data.languageId}`,
        method: "GET",
      }),
      providesTags: ["CapabilitiesDetails"],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useLazyPartnersListQuery,
  usePartnersListChangeStatusMutation,
  usePartnersListDeleteMutation,
  useAddPartnersListMutation,
  useEditPartnersListMutation,
  useLazyPartnersDetailsQuery,

  useGetCapabilitiesQuery,
  useLazyCapabilitiesListQuery,
  useCapabilitiesListChangeStatusMutation,
  useCapabilitiesListDeleteMutation,
  useAddCapabilitiesListMutation,
  useEditCapabilitiesListMutation,
  useLazyCapabilitiesDetailsQuery,

  useGetBenefitsQuery,
  useLazyBenefitsListQuery,
  useBenefitsListChangeStatusMutation,
  useBenefitsListDeleteMutation,
  useAddBenefitsListMutation,
  useEditBenefitsListMutation,
  useLazyBenefitsDetailsQuery,

  useGetHowWeWorkQuery,
  useLazyHowWeWorkListQuery,
  useHowWeWorkListChangeStatusMutation,
  useHowWeWorkListDeleteMutation,
  useAddHowWeWorkListMutation,
  useEditHowWeWorkListMutation,
  useLazyHowWeWorkDetailsQuery,

  useGetTestimonialsQuery,
  useLazyTestimonialsListQuery,
  useTestimonialsListChangeStatusMutation,
  useTestimonialsListDeleteMutation,
  useAddTestimonialsListMutation,
  useEditTestimonialsListMutation,
  useLazyTestimonialsDetailsQuery,

  useWebBannerListQuery,
  useAddWebBannerListMutation,
  useEditWebBannerListMutation,

  useMobileBannerListQuery,
  useAddMobileBannerListMutation,
  useEditMobileBannerListMutation,
} = cmsApi;

export default cmsApi;
