import { PERMISSION_SCOPES } from "components/layout/AccessControl";
import { useDispatch } from "react-redux";

import { perimssionsActions } from "store/actions/permissions";

export const usePermissionsHook = () => {
  const dispatch = useDispatch();
  const getFormatModuleData = (modules) => {
    let modulesData = [],
      formatedData = {};
    let showUsers = false,
      showContent = false;

    for (let module of modules) {
      let hasPermission = module?.permissions?.find(
        (permission) => permission.permission
      )?.id;
      if (
        hasPermission &&
        (module.category === PERMISSION_SCOPES.User ||
          module.category === PERMISSION_SCOPES.Admin_User)
      ) {
        showUsers = true;
      }
      if (
        hasPermission &&
        (module.category === PERMISSION_SCOPES.CMS_Pages ||
          module.category === PERMISSION_SCOPES.Blog)
      ) {
        showContent = true;
      }
      if (hasPermission) {
        if (module && module.category) {
          formatedData[module.category] = {};
        }
        for (let permission of module?.permissions || []) {
          if (module && module.category) {
            if (permission && permission.permission && permission.id) {
              formatedData[module.category] = {
                ...formatedData[module.category],
                [permission.permission]: permission.id,
              };
            }
          }
        }
        modulesData.push(module);
      }
    }
    if (showUsers) {
      modulesData.push({ category: PERMISSION_SCOPES.Users });
      formatedData[PERMISSION_SCOPES.Users] = { allScope: true };
    }
    if (showContent) {
      modulesData.push({ category: PERMISSION_SCOPES.Static_pages });
      formatedData[PERMISSION_SCOPES.Static_pages] = { allScope: true };
    }
    modulesData.push({ category: PERMISSION_SCOPES.Dashboard });
    modulesData.push({ category: PERMISSION_SCOPES.Support });
    modulesData.push({ category: PERMISSION_SCOPES.Common_Pages });
    formatedData[PERMISSION_SCOPES.Dashboard] = { allScope: true };
    formatedData[PERMISSION_SCOPES.Support] = { allScope: true };
    formatedData[PERMISSION_SCOPES.Common_Pages] = { allScope: true };

    return { modulesData, formatedData };
  };
  const setModulePermissions = (roleDetailsData) => {
    const adminPermissions = roleDetailsData?.role?.categoryPermissions.map(
      (permissionData) => {
        const filteredPermissions = permissionData.permissions.filter(
          (permission) => {
            return roleDetailsData?.admin?.includes(permission.id);
          }
        );

        return {
          ...permissionData,
          permissions: filteredPermissions,
        };
      }
    );

    if (adminPermissions) {
      const { formatedData, modulesData } =
        getFormatModuleData(adminPermissions);
      if (roleDetailsData?.role?.slug === "super_admin") {
        modulesData.push({ category: PERMISSION_SCOPES.Role });
        formatedData[PERMISSION_SCOPES.Role] = { allScope: true };
      }
      dispatch(
        perimssionsActions({
          modules: modulesData,
          formatedModulesData: formatedData,
          slug: roleDetailsData?.role?.slug,
          id: roleDetailsData?.role?.id,
          role: roleDetailsData?.role?.role,
          isModuleLoading: false,
        })
      );
    }
  };

  return { setModulePermissions };
};
